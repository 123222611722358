import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { HighlightsResource } from '../../resources/highlights.resource';
import { HighlightsSandbox } from './highlights.sandbox';
import { HighlightsState } from './highlights.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      HighlightsState
    ]),
  ],
  providers: [
    HighlightsResource,
    HighlightsSandbox
  ]
})
export class HighlightsModule {}
