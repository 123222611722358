import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { PlanResource } from './resources/plan.resource';
import { PlanSandbox } from './sandboxes/plan.sandbox';
import { PlanState } from './states/plan.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      PlanState
    ]),
  ],
  providers: [
    PlanResource,
    PlanSandbox
  ]
})
export class PlanModule {}
