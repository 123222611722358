import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

export class LoadingModel{
  message?: string;
  duration?: number;
}

export class LoadingControl{
  public loading;
  public loadingCtrl;

  private data = {
    message: '', // matém a referência
    duration: 0
  };

  constructor(loadingCtrl, payload: LoadingModel){
    this.data.duration = payload.duration;
    this.data.message  = payload.message;
    this.loadingCtrl   = loadingCtrl;
  }

  public async present(){
    this.loading = await this.loadingCtrl.create({
      message: this.data.message,
      duration: this.data.duration
    });

    this.loading.present();
  }

  public setMessage(data){
    this.loading.message = data;
  }

  public async dismiss(){
    this.loading.dismiss();
  }
}

@Injectable()
export class UILoadingService {
  constructor(
    private loadingController: LoadingController,
  ) {}

  public async present(payload: LoadingModel){
    const loading = new LoadingControl(this.loadingController, payload);

    await loading.present();

    return loading;
  }
}
