import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

import { IPagination } from '../interfaces/pagination.interface';
import { IHighlight } from './../interfaces/highlight.interface';

@Injectable()
export class HighlightsResource {

  constructor(
    private http: HttpClient
  ) {}

  get(): Observable<IHighlight[]> {
    return this.http.get<IHighlight[]>(`${environment.api}/highlights`);
  }

  post(payload: IHighlight) {
    return this.http.post(`${environment.api}/highlights`, {
      ...payload,
      cover: payload.cover
    });
  }

  delete(highlightId: string, storyId : string) {
    return this.http.delete(`${environment.api}/highlights/${highlightId}/${storyId}`);
  }
}