import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { IUser } from '../../interfaces/user.interface';

import { LoadMeAction } from './me.actions';
import { MeState } from './me.state';

@Injectable()
export class MeSandbox {
  @Select(MeState) me$: Observable<IUser>;

  constructor(
    private store: Store
  ) { }
  
  public load() {
    return this.store.dispatch(new LoadMeAction());
  }
}
