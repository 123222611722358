import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { PIXResource } from './resources/pix.resource';
import { PIXSandbox } from './sandboxes/pix.sandbox';
import { PIXState } from './states/pix.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      PIXState
    ]),
  ],
  providers: [
    PIXResource,
    PIXSandbox
  ]
})
export class PIXModule {}
