export class DocumentFileModel{
  status: string;
  name: string;
}

export class DocumentTypeModel{
  files: DocumentFileModel[];
  group: string;
  status: string;
  type: string;
}

export class DocumentModel{
  documents: DocumentTypeModel[];
  observations: string;
  status: string;
}
