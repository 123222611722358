import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ui-tabs',
  templateUrl: './ui-tabs.component.html',
  styleUrls: ['./ui-tabs.component.scss']
})
export class UITabsComponent implements OnInit {
  private subscriptions$ = new Subscription();

  constructor(
    private router: Router,
    private hostElement: ElementRef
  ) { }

  ngOnInit(): void {
    this.selectCurrentTabByURL();

    this.subscriptions$.add(
      this.router.events.subscribe(e => {
        if(e instanceof NavigationEnd){
          this.selectCurrentTabByURL();
        }
      })
    )
  }

  private selectCurrentTabByURL(){
    const path   = this.getCurrentPath();
    const uiTabs = this.hostElement.nativeElement;
    const uiTab  = document.querySelector(`ui-tab[routerLink="${path}"]`);

    console.log('foi');
    
    // remove todas as classes
    Array.from(uiTabs.querySelectorAll('ui-tab')).forEach((e: any) => {
      e.classList.remove('active');
    });

    uiTab.classList.add('active');
  }

  private getCurrentPath(){
    return this.router.url.split('/').pop();
  }

  ngOnDestroy(){
    this.subscriptions$.unsubscribe();
  }
}
