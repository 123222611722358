import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'ui-input-pix',
  templateUrl: './ui-input-pix.component.html',
  styleUrls: ['./ui-input-pix.component.scss']
})
export class UIInputPixComponent implements OnInit {
  @Input() required: boolean;
  @Input() controlType: FormControl;
  @Input() controlKey: FormControl;
  @Input() options;
  @Input() default;

  public types = [{
    type: 'random',
    mask: 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA',
    placeholder: 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA'
  }, {
    type: 'person',
    mask: '000.000.000-00||00.000.000/0000-00',
    placeholder: ''
  }, {
    type: 'phone',
    mask: '(00) 00000-0000',
    placeholder: '(00) 00000-0000'
  }, {
    type: 'email',
    mask: null,
    placeholder: ''
  }];

  public placeholder;
  public mask;

  @Output() onChange = new EventEmitter();

  constructor() { }

  public onSelectChange(){
    this.controlKey.setValue('');
    this.controlKey.clearValidators();
    this.controlKey.updateValueAndValidity();

    if(this.required){
      this.controlKey.setValidators([Validators.required]);
    }
  }

  ngOnInit() {
    this.required = this.required !== undefined;

    if(this.required){
      this.controlKey.setValidators([Validators.required]);
      this.controlKey.updateValueAndValidity();
    }
  }

  ngOnDestroy() {}

}
