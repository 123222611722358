import { NgModule } from '@angular/core';

import { RealPipe } from './pipes/real.pipe';

@NgModule({
  declarations: [
    RealPipe
  ],
  exports: [
    RealPipe
  ],
  imports: [],
})
export class SharedModule { }
