export class CamelcaseMask {
    static transform(value: string | null) {
      const parts = (value || '').split(' ');

      const newValue = parts.map(e => (
        e.charAt(0).toUpperCase() + e.slice(1)
      )).join(' ')

      return newValue;
    }
  }
  