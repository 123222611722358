import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { LoadPlanAction, UpdatePlanAction } from '../actions/plan.actions';
import { PlanModel } from '../models/plan.model';
import { PlanResource } from './../resources/plan.resource';

@State<PlanModel[]>({
  name: 'plans',
  defaults: []
})

@Injectable()
export class PlanState {
  constructor(
    private planResource: PlanResource
  ) { }

  @Action(LoadPlanAction)
  async loadPlan(ctx: StateContext<PlanModel[]>) {
    try{
      const res: PlanModel[] = await this.planResource
        .get()
        .toPromise();

      ctx.setState(res);

    } catch (e){
      throw e;
    }
  }

  @Action(UpdatePlanAction)
  async updatePlan(ctx: StateContext<PlanModel[]>, { payload }) {
    try{
      console.log(payload);
      const res = await this.planResource
        .update(payload)
        .toPromise();

      ctx.setState(res);

    } catch (e){
      throw e;
    }
  }
}
