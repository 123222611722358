import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ui-spinner',
  templateUrl: './ui-spinner.component.html',
  styleUrls: ['./ui-spinner.component.scss']
})
export class UISpinnerComponent implements OnInit {
  @Input() color: string;
  @Input() size: string;

  constructor() { }

  ngOnInit(): void {
  }

}
