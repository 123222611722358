import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProfileModel } from '@profile';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

import { IUser } from './../../../interfaces/user.interface';

@Injectable()
export class ProfileResource {

  constructor(
    private http: HttpClient
  ) {}

  find(payload: string): Observable<ProfileModel[]> {
    return this.http.get<ProfileModel[]>(`${environment.api}/profile/search/all?query=${payload}`);
  }

  findOne(username: string): Observable<ProfileModel> {
    return this.http.get<ProfileModel>(`${environment.api}/profile/${username}`);
  }

  findMe(): Observable<ProfileModel> {
    return this.http.get<ProfileModel>(`${environment.api}/profile`);
  }

  getMe(): Observable<IUser> {
    return this.http.get<IUser>(`${environment.api}/me`);
  }

  update(payload: ProfileModel): Observable<ProfileModel> {
    return this.http.patch<ProfileModel>(`${environment.api}/profile`, payload);
  }

  updatePic(payload: FormData) {
    return this.http.post(`${environment.api}/profile/avatar`, payload, {
      headers: new HttpHeaders({ 'ngsw-bypass': 'true' })
    });
  }
}
