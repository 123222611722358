import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { MediasResource } from '@resources/medias.resource';

import { MediasSandbox } from './medias.sandbox';
import { MediasState } from './medias.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      MediasState
    ]),
  ],
  providers: [
    MediasResource,
    MediasSandbox
  ]
})
export class MediasModule {}
