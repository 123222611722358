import { Injectable } from '@angular/core';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';

import { IIuguAccount } from '../../interfaces/iugu-account.interface';
import { ISubscriptionsStats } from '../../interfaces/subscriptions-stats.interface';
import { IuguResource } from './../../resources/iugu.resource';
import { LoadIuguAccountAction } from './iugu-account.actions';

@State<IIuguAccount>({
  name: 'iuguAccount'
})

@Injectable()
export class IuguAccountState {
  @Select(IuguAccountState) subscriptions$: Observable<ISubscriptionsStats>;

  constructor(
    private IuguResource: IuguResource
  ) { }

  @Action(LoadIuguAccountAction)
  async loadIuguAccount(ctx: StateContext<IIuguAccount>) {
    const res: IIuguAccount = await this.IuguResource
      .getAccount()
      .toPromise();

    ctx.setState(res);
  }

}
