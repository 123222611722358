import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { UIAlertService } from '../ui/services/alert.service';
import { UILoadingService } from '../ui/services/loading.service';
import { StoryResource } from './resources/story.resource';
import { StorySandbox } from './sandboxes/story.sandbox';
import { AccountStoriesState } from './states/account-stories.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      AccountStoriesState
    ]),
  ],
  providers: [
    StoryResource,
    StorySandbox,
    UIAlertService,
    UILoadingService
  ]
})
export class StoryModule {}
