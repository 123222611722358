import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProfileSandbox } from '@profile';
import { AdminPerformersComponent } from '@src/app/pages/admin-performers/admin-performers.component';

import { ISubscriptionsStats } from '../../interfaces/subscriptions-stats.interface';
import { ProfileModel } from './../../modules/profile/models/profile.model';

@Component({
  selector: 'app-account-menu-profile',
  templateUrl: './account-menu-profile.component.html',
  styleUrls: ['./account-menu-profile.component.scss']
})
export class AccountMenuProfileComponent implements OnInit {
  @Input() profile: ProfileModel;
  @Input() stats: ISubscriptionsStats;

  private count = 0;
  private timeout;

  constructor(
    private profileSandbox: ProfileSandbox,
    private modalController: ModalController
  ) {
  }

  ngOnInit() {
    this.profileSandbox
      .loadMyProfile()
      .subscribe();
  }

  async onFileUpload(event) {
    try{
      const formData = new FormData();
      const file = (event.target as HTMLInputElement).files[0];

      formData.append('file', file);

      await this.profileSandbox
        .updateMyProfilePic(formData)
        .toPromise();

      this.profileSandbox
        .loadMyProfile()
        .subscribe();
    } catch (e){
      alert(e.error.message || e.error.code)
    }
  }

  async open(){
    clearTimeout(this.timeout);
    this.count ++;

    this.timeout = setTimeout(() => {
      this.count = 0;
    }, 500);

    if(this.count == 10){
      const modal = await this.modalController.create({
        component: AdminPerformersComponent
      });
  
      return await modal.present();  
    }
  }

}
