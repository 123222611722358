import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { ProfileResource } from './resources/profile.resource';
import { ProfileSandbox } from './sandboxes/profile.sandbox';
import { ProfileState } from './states/profile.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      ProfileState
    ]),
  ],
  providers: [
    ProfileResource,
    ProfileSandbox
  ]
})
export class ProfileModule {}
