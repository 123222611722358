import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { SubscriptionResource } from './resources/subscription.resource';
import { SubscriptionSandbox } from './sandboxes/subscription.sandbox';
import { SubscriptionState } from './states/subscription.action';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      SubscriptionState
    ]),
  ],
  providers: [
    SubscriptionResource,
    SubscriptionSandbox
  ]
})
export class SubscriptionModule {}
