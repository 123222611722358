import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ui-input-outline',
  templateUrl: './ui-input-outline.component.html',
  styleUrls: ['./ui-input-outline.component.scss']
})
export class UIInputOutlineComponent implements OnInit, OnDestroy {
  @Input() control;
  @Output() onChange = new EventEmitter();
  @Input() icon;
  @Input() placeholder;
  @Input() debounce = 0;

  private subscriptions$ = new Subscription();

  constructor() { }

  ngOnInit() {
    this.watchAndEmitChangeToParent(this.control);
  }

  private watchAndEmitChangeToParent(control){
    let oldValue = control.value;

    this.subscriptions$.add(
      control.valueChanges
        .pipe(debounceTime(this.debounce))
        .subscribe(data => {
          if (oldValue === data) { return; }

          oldValue = data;

          this.onChange.emit(data);
      })
    );
  }

  ngOnDestroy(){
    this.subscriptions$.unsubscribe();
  }

}
