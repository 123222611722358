import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UISegmentButtonComponent } from './ui-segment-button.component';

@NgModule({
  declarations: [
    UISegmentButtonComponent
  ],
  exports: [
    UISegmentButtonComponent
  ],
  imports: [
    CommonModule
  ]
})
export class UISegmentButtonModule { }
