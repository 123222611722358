export class ProfileModel {
  _id: string = null;
  email: string = null;
  fullName: string = null;
  username: string = null;
  bio: string = null;
  profilePic: string = null;
  verified: boolean = null;
  stats: {
    followers: number,
    follow: number,
    midias: number
  };
}