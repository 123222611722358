import { AbstractControl } from '@angular/forms';

export class UsernameValidator {
  static validate(c: AbstractControl) {
    const reg = /^[A-Za-z0-9_\-\.]{3,50}$/;

    if (reg.test(c.value) == false) {
      return { 'username.invalid': true };
    }

    return null;
  }
}