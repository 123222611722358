export class LoadMyStoriesAction {
  static readonly type = '[Story] Load My Stories';

  constructor() { }
}

export class RemoveAccountStoryAction {
  static readonly type = '[Story] Remove Account Story by Id';

  constructor(public payload: string) { }
}

export class UploadClosedStoryAction {
  static readonly type = '[Story] Upload Story Closed';

  constructor(public payload: FormData) { }
}

export class UploadHighlightStoryAction {
  static readonly type = '[Story] Upload Story Highlight';

  constructor(public payload: FormData) { }
}