import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestUrl: Array<any> = request.url.split('/');
    const apiUrl: Array<any> = environment.api.split('/');
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken && (requestUrl[2] === apiUrl[2])) {
      const newRequest = request.clone({ setHeaders: { Authorization: `Bearer ${accessToken}`}});
      return next.handle(newRequest);
    }
    return next.handle(request);
  }

}
