import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { LoadPlanAction, UpdatePlanAction } from '../actions/plan.actions';
import { PlanModel } from '../models/plan.model';
import { PlanState } from '../states/plan.state';
import { UpdatePlanDTO } from './../dtos/update-plan.dto';

@Injectable()
export class PlanSandbox {
  @Select(PlanState) plan$: Observable<PlanModel>;

  constructor(
    private store: Store
  ) { }

  public getPlans(){
    return this.store.snapshot().plans;
  }

  public loadPlan() {
    return this.store.dispatch(new LoadPlanAction());
  }

  public updatePlan(payload: UpdatePlanDTO) {
    return this.store.dispatch(new UpdatePlanAction(payload));
  }
}
