import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

import { UpdatePlanDTO } from '../dtos/update-plan.dto';
import { PlanModel } from '../models/plan.model';

@Injectable()
export class PlanResource {

  constructor(
    private http: HttpClient
  ) {}

  get(): Observable<PlanModel[]> {
    return this.http.get<PlanModel[]>(`${environment.api}/plan/all`);
  }

  update(payload: UpdatePlanDTO): Observable<PlanModel[]> {
    return this.http.put<PlanModel[]>(`${environment.api}/plan/all`, payload);
  }
}
