import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

import { DocumentModel } from '../models/document.model';
import { WalletStatusModel } from '../models/wallet-status.model';
import { WalletModel } from '../models/wallet.model';
import { CreateWalletDTO } from './../dtos/create-wallet.dto';

@Injectable()
export class WalletResource {

  constructor(
    private http: HttpClient
  ) {}

  transfer(body) {
    console.log(body);
    return this.http.post(`${environment.api}/wallet/transfer`, body);
  }

  getBalance() {
    return this.http.get(`${environment.api}/wallet/balance`);
  }

  getBalanceOperations(params) {
    const query = new URLSearchParams(params).toString();

    return this.http.get(`${environment.api}/wallet/balance/operations?${query}`);
  }

  getRecipient() {
    return this.http.get(`${environment.api}/wallet/recipient`);
  }

  getPayables() {
    return this.http.get(`${environment.api}/wallet/payables`);
  }

  createWallet(payload: CreateWalletDTO): Observable<WalletModel> {
    return this.http.post<WalletModel>(`${environment.api}/wallet`, payload);
  }

  getWallet(): Observable<WalletModel> {
    return this.http.get<WalletModel>(`${environment.api}/wallet`);
  }

  getDocuments(): Observable<DocumentModel>{
    return this.http.get<DocumentModel>(`${environment.api}/wallet/documents`);
  }

  getStatus(): Observable<WalletStatusModel>{
    return this.http.get<WalletStatusModel>(`${environment.api}/wallet/status`);
  }

  uploadFrontDocument(payload: FormData) {
    return this.http.post(`${environment.api}/wallet/documents/front`, payload, {
      headers: new HttpHeaders({ 'ngsw-bypass': 'true' })
    });
  }

  uploadBackDocument(payload: FormData) {
    return this.http.post(`${environment.api}/wallet/documents/back`, payload, {
      headers: new HttpHeaders({ 'ngsw-bypass': 'true' })
    });
  }
}
