import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { LoadMyProfileAction, UpdateProfileAction } from '../actions/profile.actions';
import { ProfileModel } from './../models/profile.model';
import { ProfileResource } from './../resources/profile.resource';
import { ProfileState } from './../states/profile.state';

@Injectable()
export class ProfileSandbox {
  @Select(ProfileState) profile$: Observable<ProfileModel>;

  constructor(
    private store: Store,
    private resource: ProfileResource
  ) { }

  public getProfile() {
    return this.store.snapshot().profile;
  }

  public loadMyProfile() {
    return this.store.dispatch(new LoadMyProfileAction());
  }

  public updateProfile(payload) {
    return this.store.dispatch(new UpdateProfileAction(payload));
  }

  public updateMyProfilePic(formData: FormData) {
    return this.resource.updatePic(formData);
  }
}
