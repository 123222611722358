import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { StateResetAll } from 'ngxs-reset-plugin';
import { forkJoin } from 'rxjs';

import { IUser } from './../../shared/interfaces/user.interface';
import { AdminPerformersSandbox } from './../../shared/modules/admin-performers/admin-performers.sandbox';

@Component({
  selector: 'app-admin-performers',
  templateUrl: './admin-performers.component.html',
  styleUrls: ['./admin-performers.component.scss']
})
export class AdminPerformersComponent implements OnInit {
  public performers$ = this.adminPerformersSandbox.performers$;
  
  public performers: IUser[];
  public form: FormGroup;
  public loading = false;

  constructor(
    private modalController: ModalController,
    private adminPerformersSandbox: AdminPerformersSandbox,
    private store: Store
  ) {
    this.form = new FormGroup({
      search: new FormControl()
    });
  }

  async ngOnInit() {
    this.loadForkData();
  }

  closeModal(){
    this.modalController.dismiss();
  }

  async loadForkData() {
    this.loading = true;

    await forkJoin([
      this.adminPerformersSandbox.load()
    ]).toPromise();

    this.loading = false;
  }

  async doRefresh(event){
    await this.loadForkData();
    event.target.complete();
  }

  select(item: any){
    localStorage.setItem('accessToken', item.accessToken);
    this.modalController.dismiss();
    this.store.dispatch(
      new StateResetAll()
    );
  }

  onTextSearch(text){ }

  public showCard(item: IUser){
    const text= this.form.getRawValue().search;

    if(!text || text.trim().length == 0)
      return true;

    const visible = [item.account.username, item.account.fullName].some((e) => {
      return e.includes(text);
    });

    return visible;
  }

}
