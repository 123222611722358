import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

import { ISubscription } from '../interfaces/subscription.interface';
import { ISubscriptionsPagination } from '../interfaces/subscriptions-pagination.interface';
import { ISubscriptionsStats } from './../interfaces/subscriptions-stats.interface';

@Injectable()
export class SubscriptionResource {

  constructor(
    private http: HttpClient
  ) {}

  getSubscriptions(payload: any): Observable<ISubscriptionsPagination>{
    const query = new URLSearchParams(payload).toString();

    return this.http.get<ISubscriptionsPagination>(`${environment.api}/subscriptions?${query}`);
  }

  getStats(): Observable<ISubscriptionsStats> {
    return this.http.get<ISubscriptionsStats>(`${environment.api}/subscription/stats`);
  }

  revoke(_id: string): Observable<ISubscription>{
    return this.http.post<ISubscription>(`${environment.api}/subscriptions/${_id}/payment/revoke`, null);
  }

  uploadReceipt(_id: string, payload: FormData) {
    return this.http.post(`${environment.api}/subscriptions/${_id}/payment/receipt`, payload, {
      headers: new HttpHeaders({ 'ngsw-bypass': 'true' })
    });
  }
}