import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UIAvatarModule } from '@ui';

import { AccountMenuProfileComponent } from './account-menu-profile.component';

@NgModule({
  declarations: [AccountMenuProfileComponent],
  exports: [AccountMenuProfileComponent],
  imports: [
    CommonModule,
    IonicModule,
    UIAvatarModule,
    RouterModule
  ]
})
export class AccountMenuProfileModule { }
