import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMedia } from '@src/app/shared/interfaces/media.interface';
import { IUser } from '@src/app/shared/interfaces/user.interface';

@Component({
  selector: 'ui-media-card',
  templateUrl: './ui-media-card.component.html',
  styleUrls: ['./ui-media-card.component.scss']
})
export class UIMediaCardComponent implements OnInit {
  @Output() remove = new EventEmitter();
  @Output() like = new EventEmitter();

  @Input() media: IMedia;
  @Input() user: IUser;
  @Input() hideCloseMedia: boolean;

  constructor() { }

  ngOnInit() {}

  ngOnChanges(){}

  clickRemove(){
    this.remove.emit(this.media._id);
  }

  onLike(like: boolean){
    if(this.media.hasLiked == like) return;

    this.media.stats.likes = this.media.stats.likes + (like ? 1 : -1);
    this.media.hasLiked = like;
    
    this.like.emit(like);
  }
}
