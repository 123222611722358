import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ui-avatar',
  templateUrl: './ui-avatar.component.html',
  styleUrls: ['./ui-avatar.component.scss']
})
export class UIAvatarComponent implements OnInit {
  @Input() src: string;
  @Input() circled: boolean;
  @Input() addStory: string;
  @Input() circleActive: string;

  constructor() { }

  ngOnInit(): void {
  }

}
