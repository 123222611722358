export class LoadStatsAction {
  static readonly type = '[Subscriber] Load Stats Action';

  constructor() { }
}

export class UpdatePlan {
  static readonly type = '[Subscriber] Update Plan';

  constructor(public payload: number) { }
}