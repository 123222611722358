import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UIButtonComponent } from '../ui-button/ui-button.component';
import { UISpinnerModule } from '../ui-spinner/ui-spinner.module';

@NgModule({
  declarations: [
    UIButtonComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UISpinnerModule
  ],
  exports: [
    UIButtonComponent
  ]
})
export class UIButtonModule { }
