import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { IHighlight } from '../../interfaces/highlight.interface';
import { IPagination } from '../../interfaces/pagination.interface';
import { IStory } from '../../interfaces/story.interface';
import { DeleteHighlightStoryAction, LoadHighlightsAction, PostHighlightAction } from './highlights.actions';
import { HighlightsState } from './highlights.state';

@Injectable()
export class HighlightsSandbox {
  @Select(HighlightsState) highlights$: Observable<IPagination<IHighlight>>;

  constructor(
    private store: Store
  ) { }
  
  public load() {
    return this.store.dispatch(new LoadHighlightsAction());
  }

  public post(form: IHighlight) {
    return this.store.dispatch(new PostHighlightAction(form));
  }

  public deleteStory(payload: { highlight: IHighlight, story: IStory }) {
    return this.store.dispatch(new DeleteHighlightStoryAction(payload));
  }
}
