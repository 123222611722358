import { CPFValidator } from "./cpf.validator";
import { CNPJValidator } from "./cnpj.validator";

import { AbstractControl } from '@angular/forms';

export class PersonValidator {
  static validate(control: AbstractControl) {
    if (CPFValidator.validate(control) == null)
      return null;

    if (CNPJValidator.validate(control) == null)
      return null;

    return { 'person.invalid': true };
  }
}