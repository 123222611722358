import { AbstractControl } from '@angular/forms';

export class ZipcodeValidator {
  static validate(control: AbstractControl) {
    const value = trim(control.value);
    const regex = /^[0-9]{2}[0-9]{3}[0-9]{3}$/;

    return !regex.test(value)
      ? { 'zipcode.invalid': true }
      : null
  }
}

function trim(strTexto) {
  return (strTexto || '').replace(/^s+|s+$/g, '');
}