import { IPix } from '../interfaces/pix.interface';

export class LoadPIXAction {
  static readonly type = '[PIX] Load PIX';
}

export class UpdatePIXAction {
  static readonly type = '[PIX] Update PIX';

  constructor(public payload: IPix){}
}

