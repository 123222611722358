import { Injectable } from '@angular/core';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { MediasResource } from '@resources/medias.resource';
import { Observable } from 'rxjs';

import { IMedia } from '../../interfaces/media.interface';
import { IPagination } from './../../interfaces/pagination.interface';
import { DeleteMediaAction, LoadMediasAction, PostMediaAction } from './medias.actions';

@State<IPagination<IMedia>>({
  name: 'medias'
})

@Injectable()
export class MediasState {
  @Select(MediasState) medias$: Observable<IPagination<IMedia>>;

  constructor(
    private mediasResource: MediasResource
  ) { }

  @Action(LoadMediasAction)
  async load(ctx: StateContext<IPagination<IMedia>>) {
    try {
      const res: IPagination<IMedia> = await this.mediasResource.get()
        .toPromise();

      ctx.setState(res);
    } catch (e) {
      throw e;
    }
  }

  @Action(PostMediaAction)
  async post(ctx: StateContext<IMedia>, { payload }) {
    try {
      await this.mediasResource.post(payload)
        .toPromise();

        ctx.dispatch(LoadMediasAction);
    } catch (e) {
      throw e;
    }
  }

  @Action(DeleteMediaAction)
  async delete(ctx: StateContext<IMedia>, { payload }) {
    try {
      await this.mediasResource.delete(payload)
        .toPromise();

      ctx.dispatch(LoadMediasAction);
    } catch (e) {
      throw e;
    }
  }

}
