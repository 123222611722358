import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { SubscriptionResource } from '../../resources/subscriptions.resource';
import { SubscriptionsStatsSandbox } from './subscriptions-stats.sandbox';
import { SubscriptionsStatsState } from './subscriptions-stats.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      SubscriptionsStatsState
    ]),
  ],
  providers: [
    SubscriptionResource,
    SubscriptionsStatsSandbox
  ]
})
export class SubscriptionsStatsModule {}
