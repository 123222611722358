import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class SubscriptionResource {

  constructor(
    private http: HttpClient
  ) {}

  getStats() {
    return this.http.get(`${environment.api}/subscription/stats`);
  }

  updatePlan(payload: number): Observable<number> {
    return this.http.patch<number>(`${environment.api}/subscription/plan`, payload);
  }
}
