import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MediasLikeResource {

  constructor(
    private http: HttpClient
  ) {}

  like(mediaId: string) {
    return this.http.post<any>(`${environment.api}/medias/${mediaId}/like`, null);
  };

  unlike(mediaId: string) {
    return this.http.post<any>(`${environment.api}/medias/${mediaId}/unlike`, null);
  };
}
