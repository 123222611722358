import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormArray } from '@angular/forms';
import { FIELD } from '../../consts/field.const';
import { MESSAGES } from '../../consts/messages.const';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ui-input',
  templateUrl: './ui-input.component.html',
  styleUrls: ['./ui-input.component.scss']
})
export class UIInputComponent implements OnInit, OnDestroy {
  @Input() control: FormGroup;
  @Input() type: string;
  @Input() required: boolean;
  @Input() cleanable: boolean;
  @Input() label: string;
  @Input() labeled: string;
  @Input() placeholder: string;

  private scrollTop: number;

  @Output() change = new EventEmitter();

  public showFeedback = false;
  public showPassword = false;

  public inputType: string;
  public mask: string;

  private subscriptions$ = new Subscription();

  async ngOnInit() {
    this.required = this.required !== undefined;
  
    await this.setMask(this.type, this.control);

    this.showFeedbackFirstData(this.control);
    this.watchAndEmitChangeToParent(this.control);
    this.watchAndValidatePassword(this.type, this.control);
    this.setLabel(this.type);
    this.setPlaceholder(this.type);
    this.setValidator(this.type, this.control);
    this.setInputType(this.type);
  }

  private showFeedbackFirstData(control){
    const value = control.value || null;

    if (value && value.length){
      this.showFeedback = true;
    }
  }

  private watchAndEmitChangeToParent(control){
    let oldValue = control.value;

    this.subscriptions$.add(
      control.valueChanges.subscribe(data => {
        if (oldValue === data) { return; }

        oldValue = data;

        this.change.emit(data);
      })
    );
  }

  private watchAndValidatePassword(type, control){
    const passControl = control.parent.get('password');

    if (type === 'confirmPassword' && passControl){
      this.subscriptions$.add(
        passControl.statusChanges.subscribe(_ => {
          control.updateValueAndValidity();
        })
      );
    }
  }

  private setLabel(type){
    this.label = this.label || FIELD[type].label;
  }

  private setPlaceholder(type){
    this.placeholder = this.placeholder || FIELD[type].placeholder;
  }

  private setInputType(type){
    this.inputType = FIELD[type].type;
  }

  private setValidator(type, control) {
    const defaultValidators = this.required
      ? [Validators.required]
      : [];

    const validators = defaultValidators.concat(
      FIELD[type].validators
    );

    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  private setMask(type, control) {
    const mask = FIELD[type].mask;

    if (typeof mask === 'function'){
      return this.subscriptions$.add(
        control.valueChanges.subscribe((value: string) => {
          control.setValue(mask.transform(value), { emitEvent: false });
        })
      );
    }

    this.mask = mask;

    control.setValue(this.control.value);
  }

  get errorMessage() {
    const errors = this.control.errors || {};

    const array = Object.keys(errors);
    const key = array[0];

    return MESSAGES[key] || key;
  }

  onBlur(_){
    this.showFeedback = true;
    window.scrollTo(0, this.scrollTop);
  }

  onFocus(_){
    this.scrollTop = window.scrollY;
  }

  toggleVisible(e){
    e.preventDefault();

    this.showPassword = !this.showPassword;
  }

  clearValue(e){
    e.preventDefault();

    this.control.reset();
    this.showFeedback = false;
  }

  private updateTreeValidity(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];

      if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
        this.updateTreeValidity(abstractControl);
      } else {
        abstractControl.updateValueAndValidity();
      }
    });
  }

  ngOnDestroy(){
    this.subscriptions$.unsubscribe();
  }
}
