import { Validators } from '@angular/forms';

import { LowercaseMask } from '../masks/lowercase.mask';
import { UFMask } from '../masks/uf.mask';
import { UppercaseMask } from '../masks/uppercase.mask';
import { ConfirmPasswordValidator } from '../validators/confirm-password.validator';
import { CPFValidator } from '../validators/cpf.validator';
import { EmailValidator } from '../validators/email.validator';
import { PersonValidator } from '../validators/person.validator';
import { UsernameOrEmailValidator } from '../validators/username-email.validator';
import { ZipcodeValidator } from '../validators/zipcode.validator';
import { CamelcaseMask } from './../masks/camelcase.mask';
import { UsernameValidator } from './../validators/username.valitador';

export const FIELD = {
  pixRandomKey: {
    type: 'text',
    label: 'Chave PIX (Aleatória)',
    mask: 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA',
    placeholder: 'Insira a chave PIX',
    validators: [
      Validators.minLength(32)
    ]
  },
  pix: {
    type: 'text',
    label: 'Chave Pix',
    mask: '(00) 00000-0000',
    placeholder: 'Insira a chave PIX',
    validators: [
      Validators.minLength(32)
    ]
  },
  usernameOrEmail: {
    type: 'emailOrUsername',
    label: 'E-mail ou Username',
    mask: LowercaseMask,
    placeholder: 'Qual é o seu e-mail ou username?',
    validators: [UsernameOrEmailValidator]
  },
  username: {
    type: 'username',
    label: 'Username',
    mask: LowercaseMask,
    placeholder: 'Seu username',
    validators: [ UsernameValidator ]
  },
  email: {
    type: 'email',
    label: 'E-mail',
    mask: null,
    placeholder: 'Seu e-mail',
    validators: [ EmailValidator ]
  },
  password: {
    type: 'password',
    label: 'Senha',
    mask: null,
    placeholder: 'Sua senha',
    validators: [ Validators.minLength(6) ]
  },
  choosePassword: {
    type: 'password',
    label: 'Senha',
    mask: null,
    placeholder: 'Senha',
    validators: [ Validators.minLength(6) ]
  },
  confirmPassword: {
    type: 'password',
    label: 'Confirmar Senha',
    mask: null,
    placeholder: 'Confirmar Senha',
    validators: [
      Validators.minLength(4),
      ConfirmPasswordValidator
    ]
  },
  name: {
    type: 'text',
    label: 'Nome',
    mask: null,
    placeholder: 'Nome',
    validators: []
  },
  fullName: {
    type: 'text',
    label: 'Nome Completo',
    mask: UppercaseMask,
    placeholder: 'Nome Completo',
    validators: []
  },
  person: {
    type: 'text',
    label: 'CPF ou CNPJ',
    mask: '000.000.000-00||00.000.000/0000-00',
    placeholder: 'CPF ou CNPJ',
    validators: [ PersonValidator ]
  },
  cpf: {
    type: 'text',
    label: 'CPF',
    mask: '000.000.000-00',
    placeholder: 'CPF',
    validators: [ CPFValidator ]
  },
  zipcode: {
    type: 'text',
    label: 'CEP',
    mask: '00000-000',
    placeholder: 'CEP',
    validators: [ ZipcodeValidator ]
  },
  street: {
    type: 'text',
    label: 'Nome da Rua',
    mask: CamelcaseMask,
    placeholder: 'Rua',
    validators: []
  },
  streetNumber: {
    type: 'text',
    label: 'Número da Casa',
    mask: '0*',
    placeholder: 'Número',
    validators: [ ]
  },
  complement: {
    type: 'text',
    label: 'Complemento',
    mask: null,
    placeholder: 'Complemento',
    validators: [ ]
  },
  neighborhood: {
    type: 'text',
    label: 'Bairro',
    mask: CamelcaseMask,
    placeholder: 'Bairro',
    validators: [ ]
  },
  city: {
    type: 'text',
    label: 'Cidade',
    mask: CamelcaseMask,
    placeholder: 'Cidade',
    validators: [ ]
  },
  state: {
    type: 'text',
    label: 'Estado',
    mask: UFMask,
    placeholder: 'UF',
    validators: [ 
      Validators.minLength(2),
      Validators.maxLength(2)
    ]
  },
  phoneNumber: {
    type: 'tel',
    label: 'Celular',
    mask: '(00) 00000-0000',
    placeholder: 'Celular',
    validators: [ Validators.minLength(11) ]
  },
  date: {
    type: 'text',
    label: 'Data',
    mask: '00/00/0000',
    placeholder: 'DD/MM/AAAA',
    validators: [ ]
  },
  number: {
    type: 'number',
    label: 'Número',
    placeholder: 'Número',
    validators: [ ]
  },
  cardNumber: {
    type: 'text',
    label: 'Número do cartão',
    mask: '0000 0000 0000 0000',
    placeholder: '0000 0000 0000 0000',
    validators: [ 
      Validators.minLength(16),
      Validators.maxLength(16) 
    ]
  },
  cardHolderName: {
    type: 'text',
    label: 'Titular',
    mask: UppercaseMask,
    placeholder: 'Nome do Titular',
    validators: [ ]
  },
  cardExpDate: {
    type: 'text',
    label: 'Validade',
    mask: '00/00',
    placeholder: 'MM/AA',
    validators: [ ]
  },
  cardCVV: {
    type: 'text',
    label: 'CVV',
    mask: '000',
    placeholder: 'CVV',
    validators: [ ]
  }
}
