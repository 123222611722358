import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { IUser } from './../../interfaces/user.interface';
import { PerformerResource } from './../../resources/performer.resource';
import { LoadAdminPerformersAction } from './admin-performers.actions';

@State<IUser[]>({
  name: 'adminPerformers'
})

@Injectable()
export class AdminPerformersState {

  constructor(
    private performerResource: PerformerResource
  ) { }

  @Action(LoadAdminPerformersAction)
  async loadPerformers(ctx: StateContext<IUser[]>) {
    try{
      const res: IUser[] = await this.performerResource.getPerformers()
        .toPromise();

      ctx.setState(res);
    }catch(e){
      throw e;
    }
  }
}
