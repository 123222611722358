import { BalanceModel } from './../models/balance.model';
import { LoadBalanceAction } from './../actions/wallet.actions';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { WalletResource } from '../resources/wallet.resource';

@State<BalanceModel>({
  name: 'balance',
  defaults: {
    totalBalance: null
  }
})

@Injectable()
export class BalanceState {
  @Selector()
  static balance(state: BalanceModel) {
    return state;
  }

  constructor(
    private walletResource: WalletResource
  ) { }

  @Action(LoadBalanceAction)
  loadBalance(ctx: StateContext<BalanceModel>) {
    return this.walletResource.getBalance().pipe(
      map((payload: BalanceModel) => {
        console.log(payload);
        ctx.setState(payload);
      })
    );
  }
}
