import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { BillingResource } from '../../resources/billing.resource';
import { BillingsSandbox } from './billings.sandbox';
import { BillingsState } from './billings.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      BillingsState
    ]),
  ],
  providers: [
    BillingResource,
    BillingsSandbox
  ]
})
export class BillingsModule {}
