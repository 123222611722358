import { Injectable } from '@angular/core';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';

import { IPagination } from './../../interfaces/pagination.interface';
import { IStory } from './../../interfaces/story.interface';
import { StoriesArchivedResource } from './../../resources/stories-archived.resource';
import { LoadStoriesArchivedAction } from './stories-archived.actions';

@State<IPagination<IStory>>({
  name: 'storiesArchived'
})

@Injectable()
export class StoriesArchivedState {
  @Select(StoriesArchivedState) stories$: Observable<IPagination<IStory>>;

  constructor(
    private storiesArchivedResource: StoriesArchivedResource
  ) { }

  @Action(LoadStoriesArchivedAction)
  async load(ctx: StateContext<IPagination<IStory>>) {
    try {
      const res: IPagination<IStory> = await this.storiesArchivedResource.get()
        .toPromise();

      ctx.setState(res);
    } catch (e) {
      throw e;
    }
  }
}
