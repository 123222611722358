import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';

import { LoadStatsAction, UpdatePlan } from '../actions/subscription.actions';
import { SubscriptionModel, SubscriptionStatsModel } from '../models/subscription.model';
import { SubscriptionResource } from '../resources/subscription.resource';

@State<SubscriptionModel>({
  name: 'subscription',
  defaults: {
    stats: null,
    isLoading: false
  }
})

@Injectable()
export class SubscriptionState {

  @Selector()
  static isLoading(state: SubscriptionModel) {
    return state.isLoading;
  }

  @Selector()
  static stats(state: SubscriptionModel) {
    return state.stats;
  }

  constructor(
    private subscriptionResource: SubscriptionResource
  ) { }

  @Action(LoadStatsAction)
  loadStatsAction(ctx: StateContext<SubscriptionModel>) {
    return this.subscriptionResource.getStats().pipe(
      map((stats: SubscriptionStatsModel) => {
        ctx.patchState({ stats });
      })
    );
  }

  @Action(UpdatePlan)
  updatePlan(ctx: StateContext<SubscriptionModel>, action: UpdatePlan) {
    ctx.patchState({isLoading: true});
    return this.subscriptionResource.updatePlan(action.payload).pipe(
      map((res: number) => {
        console.log('res', res)
      })
    );
  }
}
