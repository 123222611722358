import { FormGroup, AbstractControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ui-datapicker',
  templateUrl: './ui-datapicker.component.html',
  styleUrls: ['./ui-datapicker.component.scss']
})
export class UIDatapickerComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() label: string;

  public min: string;
  public max: string;
  public months: string[];
  public customPickerOptions: any;

  constructor() {
    const year = new Date().getFullYear();
    this.min = `${year - 50}-01-01T00:00:00`;
    this.max = `${year - 18}-12-31T00:00:00`;
    this.months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    this.customPickerOptions = {
      buttons: [{
        text: 'Ok',
        handler: (element) => {
          this.control.setValue(`${element.year.value}-${element.month.value}-${('0' + element.day.value).slice(-2)}`)
        }
      }]
    };
  }

  ngOnInit(): void {
  }

}
