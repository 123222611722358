import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { PaymentResource } from '../../resources/payment.resource';
import { AdminPerformersSandbox } from './admin-performers.sandbox';
import { AdminPerformersState } from './admin-performers.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      AdminPerformersState
    ]),
  ],
  providers: [
    PaymentResource,
    AdminPerformersSandbox
  ]
})
export class AdminPerformersStateModule {}
