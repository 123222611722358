import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ui-button',
  templateUrl: './ui-button.component.html',
  styleUrls: ['./ui-button.component.scss']
})
export class UIButtonComponent {
  @Input() type: string;
  @Input() disabled: boolean;
  @Input() size: string;
  @Input() loading: string;

  constructor() { }
}
