import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { LoadPIXAction, UpdatePIXAction } from './../actions/pix.actions';
import { IPix } from './../interfaces/pix.interface';
import { PIXState } from './../states/pix.state';

@Injectable()
export class PIXSandbox {
  @Select(PIXState) pix$: Observable<IPix>;

  constructor(
    private store: Store
  ) { }

  public snapshot(): IPix{
    return this.store.snapshot().pix;
  }

  public load() {
    return this.store.dispatch(new LoadPIXAction());
  }

  public update(payload: IPix) {
    return this.store.dispatch(new UpdatePIXAction(payload));
  }
}
