import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ui-segment',
  templateUrl: './ui-segment.component.html',
  styleUrls: ['./ui-segment.component.scss'],
})
export class UISegmentComponent implements OnInit {
  @Input() value: string;
  @Output() onChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.selectCurrentTabByURL();
    this.addClickListener();
  }

  private selectCurrentTabByURL(){
    // const elem    = this.hostElement.nativeElement;
    const buttons = document.querySelectorAll('ui-segment-button');
    const button  = document.querySelector(`ui-segment-button[value="${this.value}"]`);

    // remove todas as classes
    Array.from(buttons).forEach((e: any) => {
      e.classList.remove('active');
    });

    button.classList.add('active');
    this.onChange.emit(button.getAttribute('value'));
  }

  private addClickListener(){
    const buttons = document.querySelectorAll('ui-segment-button');

    Array.from(buttons).forEach((elem: any) => {
      elem.addEventListener('click', () => {
        // remove todas as classes
        Array.from(buttons).forEach((e: any) => {
          e.classList.remove('active');
        });

        this.onChange.emit(elem.getAttribute('value'));

        elem.classList.add('active');
      });
    });
  }
}
