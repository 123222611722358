import { Injectable } from '@angular/core';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ISeen } from '../../interfaces/seen.interface';
import { SetSeenAction } from './seen.actions';

@State<ISeen>({
  name: 'seen',
  defaults: {
    current: 0,
    lastSeen: 0
  }
})

@Injectable()
export class SeenState {
  @Select(SeenState) seen$: Observable<string>;

  constructor( ) { }

  @Action(SetSeenAction)
  async set(ctx: StateContext<ISeen>, { payload }) {
    const state = ctx.getState();

    const lastSeen = state.lastSeen < payload
      ? payload
      : state.lastSeen;

    console.log({
      lastSeen: lastSeen,
      current: payload
    })

    ctx.setState({ 
      lastSeen: lastSeen,
      current: payload
    })
  }

}
