import { Injectable } from '@angular/core';
import { IPaymentsGetDTO } from '@dtos/payments-get.dto';
import { Action, State, StateContext } from '@ngxs/store';
import { PaymentResource } from '@resources/payment.resource';
import { IPayment } from '@src/app/shared/interfaces/payment.interface';

import { ApproveReceiptPixAction, LoadPixReceiptsAction, ReproveReceiptPixAction } from '../actions/pix-receipt.actions';

@State<IPaymentsGetDTO>({
  name: 'pixReceipts',
  defaults: {
    data: []
  }
})

@Injectable()
export class PixReceiptsState {
  constructor(
    private paymentResource: PaymentResource
  ) { }

  @Action(LoadPixReceiptsAction)
  async load(ctx: StateContext<IPaymentsGetDTO>) {
    try{
      const res: IPaymentsGetDTO = await this.paymentResource.get().toPromise();

      ctx.setState(res);

    } catch (e){
      throw e;
    }
  }

  @Action(ApproveReceiptPixAction)
  async approve(ctx: StateContext<IPayment>, { payload }) {
    try{
      this.paymentResource.approve(payload).toPromise();
    } catch (e){
      throw e;
    }
  }

  @Action(ReproveReceiptPixAction)
  async reprove(ctx: StateContext<IPayment>, { payload }) {
    try{
      await this.paymentResource.reprove(payload).toPromise();
    } catch (e){
      throw e;
    }
  }

}
