import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LoginDTO } from '../dtos/login.dto';
import { RegisterDTO } from '../dtos/register.dto';
import { SessionModel } from '../models/session.model';
import { environment } from './../../../../../environments/environment';

@Injectable()
export class AuthResource {

  constructor(
    private http: HttpClient
  ) {}

  login(payload: LoginDTO): Observable<SessionModel> {
    return this.http.post<any>(`${environment.api}/auth/login`, payload);
  }

  register(payload: RegisterDTO): Observable<SessionModel> {
    payload.phoneNumber = '+55' + payload.phoneNumber;

    return this.http.post<any>(`${environment.api}/auth/register`, payload);
  }
}
