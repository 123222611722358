import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ISubscriptionsStats } from '../../interfaces/subscriptions-stats.interface';
import { LoadSubscriptionsStatsAction } from './subscriptions-stats.actions';
import { SubscriptionsStatsState } from './subscriptions-stats.state';

@Injectable()
export class SubscriptionsStatsSandbox {
  @Select(SubscriptionsStatsState) stats$: Observable<ISubscriptionsStats>;

  constructor(
    private store: Store
  ) { }
  public loadSubscriptionsStats() {
    return this.store.dispatch(new LoadSubscriptionsStatsAction());
  }
}
