import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UITabComponent } from './ui-tab.component';

@NgModule({
  declarations: [
    UITabComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    UITabComponent
  ]
})
export class UITabModule { }
