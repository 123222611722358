import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { IuguResource } from '@resources/iugu.resource';

import { IuguAccountSandbox } from './iugu-account-sandbox';
import { IuguAccountState } from './iugu-account.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      IuguAccountState
    ]),
  ],
  providers: [
    IuguResource,
    IuguAccountSandbox
  ]
})
export class IuguAccountStateModule {}
