export class StatsModel{
  views: number;
  users: number;
}

export class StoryModel {
  _id: string;
  processed = false;
  user: string = null;
  audience: string = null;
  displayUrl: string = null;
  src: string = null;
  expireAt: string = null;
  stats: StatsModel;
}