import { Injectable } from '@angular/core';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';

import { IUser } from './../../interfaces/user.interface';
import { ProfileResource } from './../profile/resources/profile.resource';
import { LoadMeAction } from './me.actions';

@State<IUser>({
  name: 'me'
})

@Injectable()
export class MeState {
  @Select(MeState) me$: Observable<IUser>;

  constructor(
    private profileResource: ProfileResource
  ) { }

  @Action(LoadMeAction)
  async load(ctx: StateContext<IUser>) {
    try {
      const res: IUser = await this.profileResource.getMe()
        .toPromise();

      ctx.setState(res);
    } catch (e) {
      throw e;
    }
  }
}
