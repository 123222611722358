import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { CreateWalletDTO } from '@wallet';
import { Observable } from 'rxjs';

import {
  ClearWalletOperationsAction,
  CreateWalletAction,
  LoadBalanceAction,
  LoadWalletStatusAction,
} from '../actions/wallet.actions';
import { OperationModel } from '../models/operation.model';
import { WalletStatusModel } from '../models/wallet-status.model';
import { WalletResource } from '../resources/wallet.resource';
import { BalanceState } from '../states/balance.state';
import {
  LoadBalanceOperationsAction,
  LoadPayablesAction,
  LoadRecipientAction,
  LoadWalletAction,
} from './../actions/wallet.actions';
import { WalletSearchDTO } from './../dtos/wallet-search.dto';
import { BalanceModel } from './../models/balance.model';
import { WalletModel } from './../models/wallet.model';
import { OperationsState } from './../states/operations.state';
import { WalletStatusState } from './../states/wallet-status';
import { WalletState } from './../states/wallet.state';

@Injectable()
export class WalletSandbox {
  @Select(BalanceState) balance$: Observable<BalanceModel>;
  @Select(OperationsState) operations$: Observable<OperationModel[]>;
  @Select(WalletState) wallet$: Observable<WalletModel>;
  @Select(WalletStatusState) walletStatus$: Observable<WalletStatusModel>;

  public query: WalletSearchDTO = {
    offset: 0,
    limit: 20
  };

  constructor(
    private store: Store,
    private walletResource: WalletResource
  ) { }

  public getOperations(){
    return this.store.snapshot().operations;
  }

  public resetSearchOffset(){
    this.query.offset = 0;
  }

  public incSearchOffset(){
    this.query.offset += this.query.limit;
  }

  public resetSearch(){
    this.query = {
      offset: 0,
      limit: 20
    };

    return this.store.dispatch(new ClearWalletOperationsAction());
  }

  public loadBalance() {
    return this.store.dispatch(new LoadBalanceAction());
  }

  public loadBalanceOperations() {
    return this.store.dispatch(new LoadBalanceOperationsAction(this.query));
  }

  public loadRecipient() {
    return this.store.dispatch(new LoadRecipientAction());
  }

  public loadPayables() {
    return this.store.dispatch(new LoadPayablesAction());
  }

  public loadWallet() {
    return this.store.dispatch(new LoadWalletAction());
  }

  public createWallet(payload: CreateWalletDTO) {
    return this.store.dispatch(new CreateWalletAction(payload));
  }

  public loadWalletStatus() {
    return this.store.dispatch(new LoadWalletStatusAction());
  }

  public uploadFrontDocument(formData: FormData) {
    return this.walletResource.uploadFrontDocument(formData);
  }

  public uploadBackDocument(formData: FormData) {
    return this.walletResource.uploadBackDocument(formData);
  }
}
