import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { SeenSandbox } from './seen.sandbox';
import { SeenState } from './seen.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      SeenState
    ]),
  ],
  providers: [
    SeenSandbox
  ]
})
export class SeenModule {}
