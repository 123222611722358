import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { IMedia } from './../../interfaces/media.interface';
import { IPagination } from './../../interfaces/pagination.interface';
import { DeleteMediaAction, LoadMediasAction, PostMediaAction } from './medias.actions';
import { MediasState } from './medias.state';

@Injectable()
export class MediasSandbox {
  @Select(MediasState) medias$: Observable<IPagination<IMedia>>;

  constructor(
    private store: Store
  ) { }
  
  public load() {
    return this.store.dispatch(new LoadMediasAction());
  }

  public post(form: FormData) {
    return this.store.dispatch(new PostMediaAction(form));
  }

  public delete(_id: string) {
    return this.store.dispatch(new DeleteMediaAction(_id));
  }
}
