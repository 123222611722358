import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-button-thin',
  templateUrl: './ui-button-thin.component.html',
  styleUrls: ['./ui-button-thin.component.scss']
})
export class UIButtonThinComponent implements OnInit {
  @Input() icon: string;

  constructor() { }

  ngOnInit(): void {
  }

}
