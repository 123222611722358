import { IPayment } from "@src/app/shared/interfaces/payment.interface";

export class LoadPixReceiptsAction {
  static readonly type = '[PixReceipts] Load Pix Receipts';
}

export class ApproveReceiptPixAction {
  static readonly type = '[PixReceipts] Approve Pix Receipt';

  constructor(public payload: string){}
}

export class ReproveReceiptPixAction {
  static readonly type = '[PixReceipts] Reprove Pix Receipt';

  constructor(public payload: string){}
}


