import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ui-dropdown-outline',
  templateUrl: './ui-dropdown-outline.component.html',
  styleUrls: ['./ui-dropdown-outline.component.scss']
})
export class UIDropdownOutlineComponent implements OnInit {
  @Input() control: FormControl;
  @Input() options;
  @Input() default;

  @Output() onChange = new EventEmitter();

  private subscriptions$ = new Subscription();

  constructor() { }

  ngOnInit() {
    this.control.setValue(this.default);

    this.watchAndEmitChangeToParent(this.control);
  }

  private watchAndEmitChangeToParent(control){
    let oldValue = control.value;

    this.subscriptions$.add(
      control.valueChanges
        .subscribe(data => {
          if (oldValue === data) { return; }

          oldValue = data;

          this.onChange.emit(data);
      })
    );
  }

  ngOnDestroy(){
    this.subscriptions$.unsubscribe();
  }

}
