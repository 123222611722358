import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { IPagination } from './../../interfaces/pagination.interface';
import { IStory } from './../../interfaces/story.interface';
import { LoadStoriesArchivedAction } from './stories-archived.actions';
import { StoriesArchivedState } from './stories-archived.state';

@Injectable()
export class StoriesArchivedSandbox {
  @Select(StoriesArchivedState) storie$: Observable<IPagination<IStory>>;

  constructor(
    private store: Store
  ) { }

  public snapshot(){
    return this.store.snapshot().storiesArchived;
  }
  
  public load() {
    return this.store.dispatch(new LoadStoriesArchivedAction());
  }
}
