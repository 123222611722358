import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { LoadStatsAction, UpdatePlan } from '../actions/subscription.actions';
import { SubscriptionState } from '../states/subscription.action';
import { SubscriptionStatsModel } from './../models/subscription.model';

@Injectable()
export class SubscriptionSandbox {
  @Select(SubscriptionState.stats) stats$: Observable<SubscriptionStatsModel>;

  constructor(
    private store: Store
  ) { }

  public loadStats() {
    return this.store.dispatch(new LoadStatsAction());
  }

  public updatePlan(value: number) {
    return this.store.dispatch(new UpdatePlan(value));
  }
}
