import { Injectable } from '@angular/core';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { AccountSettingResource } from '@resources/account-setting.resource';
import { Observable } from 'rxjs';

import { IAccountSetting } from './../../interfaces/account-setting.interface';
import { GetSettingAction, PatchSettingAction } from './setting.actions';

@State<IAccountSetting>({
  name: 'accountSetting'
})

@Injectable()
export class SettingState {
  @Select(SettingState) setting$: Observable<IAccountSetting>;

  constructor(
    private settingResource: AccountSettingResource
  ) { }

  @Action(GetSettingAction)
  async set(ctx: StateContext<IAccountSetting>) {
    try {
      const res: IAccountSetting = await this.settingResource.get()
        .toPromise();

      ctx.setState(res);
    } catch (e) {
      throw e;
    }
  }

  @Action(PatchSettingAction)
  async patch(ctx: StateContext<IAccountSetting>, { payload }) {
    try {
      console.log(payload);
      const res: IAccountSetting = await this.settingResource.patch(payload)
        .toPromise();

      ctx.setState(res);
    } catch (e) {
      throw e;
    }
  }

}
