import { MeModule } from './../../modules/me/me.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UIMediaCardModule } from '../../modules/ui/components/ui-media-card/ui-media-card.module';
import { MediasModule } from './../../modules/medias/medias.module';
import { MediasFeedComponent } from './medias-feed.component';

@NgModule({
  declarations: [MediasFeedComponent],
  exports: [MediasFeedComponent],
  imports: [
    CommonModule,
    UIMediaCardModule,
    MediasModule,
    MeModule
  ]
})
export class MediasFeedModule { }
