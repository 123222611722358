import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { WalletResource } from '../resources/wallet.resource';
import { CreateWalletAction, LoadWalletAction } from './../actions/wallet.actions';
import { WalletModel } from './../models/wallet.model';

@State<WalletModel>({
  name: 'wallet',
  defaults: {
    name: null,
    document: null,
    street: null,
    streetNumber: null,
    neighborhood: null,
    zipcode: null,
    meta: {
      loading: false
    }
  }
})

@Injectable()
export class WalletState {
  constructor(
    private walletResource: WalletResource
  ) { }

  @Action(CreateWalletAction)
  async createWallet(ctx: StateContext<WalletModel>, { payload }) {
    try{
      ctx.patchState({ meta: { loading: true } });

      const res = await this.walletResource
        .createWallet(payload)
        .toPromise();

      ctx.setState({
        ...res,
        meta: { loading: false }
      });
    } catch (e){
      ctx.patchState({ meta: { loading: false } });
      throw e;
    }
  }

  @Action(LoadWalletAction)
  async getWallet(ctx: StateContext<WalletModel>, { payload }) {
    try{
      ctx.patchState({ meta: { loading: true } });

      const res = await this.walletResource
        .getWallet()
        .toPromise();

      ctx.setState({
        ...res,
        meta: { loading: false }
      });
    } catch (e){
      ctx.patchState({ meta: { loading: false } });
      throw e;
    }
  }
}
