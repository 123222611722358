import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { AccountSettingResource } from '@resources/account-setting.resource';

import { SettingSandbox } from './setting.sandbox';
import { SettingState } from './setting.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      SettingState
    ]),
  ],
  providers: [
    SettingSandbox,
    AccountSettingResource
  ]
})
export class SettingModule {}
