import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

import { IPix } from '../interfaces/pix.interface';

@Injectable()
export class PIXResource {

  constructor(
    private http: HttpClient
  ) {}

  get(): Observable<IPix> {
    return this.http.get<IPix>(`${environment.api}/account/pix`);
  }

  update(payload: IPix): Observable<IPix> {
    return this.http.put<IPix>(`${environment.api}/account/pix`, payload);
  }
}
