export class LoadMediasAction {
  static readonly type = '[Medias] Load Medias'

  constructor() { }
};

export class PostMediaAction {
  static readonly type = '[Medias] Post Media'

  constructor(public payload: FormData) { }
};

export class DeleteMediaAction {
  static readonly type = '[Medias] Delete Media'

  constructor(public payload: string) { }
};