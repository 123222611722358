export class UFMask {
  static transform(value: string | null) {
    value = value || '';

    return value
      .toUpperCase()
      .replace(/\d/g, '')
      .slice(0, 2)
      .trim();
  }
}
