import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { WalletResource } from './resources/wallet.resource';
import { WalletSandbox } from './sandboxes/wallet.sandbox';
import { BalanceState } from './states/balance.state';
import { OperationsState } from './states/operations.state';
import { WalletStatusState } from './states/wallet-status';
import { WalletState } from './states/wallet.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      BalanceState,
      OperationsState,
      WalletState,
      WalletStatusState
    ]),
  ],
  providers: [
    WalletResource,
    WalletSandbox
  ]
})
export class WalletModule {}
