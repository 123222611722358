import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { CapitalizeFirstsDirective } from '../../directives/capitalize-firsts.directive';
import { UIInputComponent } from './ui-input.component';

@NgModule({
  declarations: [
    UIInputComponent,
    CapitalizeFirstsDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    IonicModule
  ],
  exports: [
    UIInputComponent
  ]
})
export class UIInputModule { }
