import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccountBalanceModule } from '@components/account-balance/account-balance.module';
import { AccountMenuProfileModule } from '@components/account-menu-profile/account-menu-profile.module';
import { IonicModule } from '@ionic/angular';
import { SubscriptionModule } from '@subscription';
import { UIHeaderModule, UIMenuContainerModule, UIMenuItemModule } from '@ui';
import { WalletModule } from '@wallet';

import { AdminPerformersModule } from '../admin-performers/admin-performers.module';
import { BillingsModule } from './../../shared/modules/billings/billings.module';
import { ProfileModule } from './../../shared/modules/profile/profile.module';
import { SettingModule } from './../../shared/modules/setting/setting.module';
import { SubscriptionsStatsModule } from './../../shared/modules/subscriptions-stats/subscriptions-stats.module';
import { AccountMenuRoutingModule } from './account-menu-routing.module';
import { AccountMenuPage } from './account-menu.page';

@NgModule({
  declarations: [AccountMenuPage],
  imports: [
    CommonModule,
    AccountMenuRoutingModule,
    UIHeaderModule,
    UIMenuContainerModule,
    UIMenuItemModule,
    IonicModule,
    AccountMenuProfileModule,
    ProfileModule,
    SubscriptionModule,
    AccountBalanceModule,
    WalletModule,
    SubscriptionsStatsModule,
    AdminPerformersModule,
    SettingModule,
    BillingsModule
  ]
})
export class AccountMenuModule { }
