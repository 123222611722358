import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'real' })
export class RealPipe implements PipeTransform {
  transform(value: string): string {
    return `R$ ` + this.formatReal(value);
  }

  private formatReal(int) {
    let tmp = Math.abs(int) + '';

    tmp = tmp.replace(/([0-9]{2})$/g, ',$1');

    if (tmp.length > 6){
      tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
    }

    return tmp;
  }
}
