import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { AccountStatsResource } from '@resources/account-stats.resource';

import { AccountStatsSandbox } from './account-stats.sandbox';
import { AccountStatsState } from './account-stats.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      AccountStatsState
    ]),
  ],
  providers: [
    AccountStatsResource,
    AccountStatsSandbox
  ]
})
export class AccountStatsModule {}
