import { StoryModel } from './../models/story.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class StoryResource {

  constructor(
    private http: HttpClient
  ) {}

  uploadHighlight(payload: FormData) {
    return this.http.post(`${environment.api}/stories/files/highlight`, payload, {
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({ 'ngsw-bypass': 'true' })
    });
  }

  uploadClosed(payload: FormData) {
    return this.http.post(`${environment.api}/stories/files/closed`, payload, {
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({ 'ngsw-bypass': 'true' })
    });
  }

  findMyStories(): Observable<StoryModel[]> {
    return this.http.get<StoryModel[]>(`${environment.api}/stories/me`);
  }

  findById(_id: string): Observable<StoryModel> {
    return this.http.get<StoryModel>(`${environment.api}/stories/${_id}`);
  }

  deleteOne(_id: string) {
    return this.http.delete(`${environment.api}/stories/${_id}`);
  }
}
