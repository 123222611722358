import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { PIXSandbox } from '@pix';
import { PixReceiptSandbox } from '@pix-receipts';
import { ProfileSandbox } from '@profile';
import { PlanSandbox } from '@src/app/shared/modules/plan';
import { SubscriptionSandbox } from '@src/app/shared/modules/subscription';
import { WalletSandbox } from '@wallet';
import { StateResetAll } from 'ngxs-reset-plugin';
import { forkJoin, Observable } from 'rxjs';

import { SubscriptionsStatsSandbox } from '../../shared/modules/subscriptions-stats/subscriptions-stats.sandbox';
import { IBilling } from './../../shared/interfaces/billings.interface';
import { IResponse } from './../../shared/interfaces/response.interface';
import { BillingsSandbox } from './../../shared/modules/billings/billings.sandbox';
import { MeSandbox } from './../../shared/modules/me/me.sandbox';
import { SettingSandbox } from './../../shared/modules/setting/setting.sandbox';

@Component({
  templateUrl: './account-menu.page.html',
  styleUrls: ['./account-menu.page.scss']
})
export class AccountMenuPage implements OnInit {
  public me$ = this.meSandbox.me$;
  public stats$ = this.subscriptionsStatsSandbox.stats$;
  public profile$ = this.profileSandbox.profile$;
  public balance$ = this.walletSandbox.balance$;
  public operations$ = this.walletSandbox.operations$;
  public wallet$ = this.walletSandbox.wallet$;
  public walletStatus$ = this.walletSandbox.walletStatus$;
  public pixReceipts$ = this.pixReceiptSandbox.pixReceipts$;
  public billings$: Observable<IResponse<IBilling>> = this.billingsSandbox.billings$;
  public countBillingPending: number;

  constructor(
    private profileSandbox: ProfileSandbox,
    private subscriptionsStatsSandbox: SubscriptionsStatsSandbox,
    private walletSandbox: WalletSandbox,
    private navCtrl: NavController,
    private PIXSandbox: PIXSandbox,
    private subscriptionSandbox: SubscriptionSandbox,
    private planSandbox: PlanSandbox,
    private pixReceiptSandbox: PixReceiptSandbox,
    private store: Store,
    private settingSandbox: SettingSandbox,
    private billingsSandbox: BillingsSandbox,
    private meSandbox: MeSandbox
  ) { }

  ngOnInit(): void {
    this.loadForkData().subscribe(() => {
      console.log('dados carregados sucesso');
    });

    this.billings$.subscribe((billings: IResponse<IBilling>) => {
      this.countBillingPending = billings.data.filter(e => !e.receiptUrl).length;

      console.log(this.countBillingPending);
    });
  }

  public openSupport() {
    const text = `Olá, preciso de ajuda com o meu.clozzy.com.br`;
    const url = 'https://api.whatsapp.com/send?phone=+5541996654309&text=' + text;
    const win = window.open(url, '_top');
    win.focus();
  }

  // loadZipData() {
  //   return zip(
  //     this.subscriptionSandbox.loadStats(),
  //     this.walletSandbox.loadBalance(),
  //     this.walletSandbox.loadBalanceOperations(),
  //     this.walletSandbox.loadRecipient()
  //   );
  // }

  loadForkData() {
    return forkJoin([
      this.subscriptionsStatsSandbox.loadSubscriptionsStats(),
      this.subscriptionSandbox.loadStats(),
      this.walletSandbox.loadWallet(),
      this.walletSandbox.loadBalance(),
      this.walletSandbox.loadBalanceOperations(),
      this.profileSandbox.loadMyProfile(),
      this.planSandbox.loadPlan(),
      this.walletSandbox.loadWalletStatus(),
      this.PIXSandbox.load(),
      this.pixReceiptSandbox.load(),
      this.settingSandbox.get(),
      this.billingsSandbox.load()
    ]);
  }

  doRefresh(event) {
    this.loadForkData().subscribe(_ => {
      event.target.complete();
    }, err => {
      event.target.complete();
    });
  }

  logout() {
    this.navCtrl.navigateRoot('/auth');
    localStorage.clear();
    this.store.dispatch(
      new StateResetAll()
    );
  }

}
