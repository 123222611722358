import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

import { IAccountStats } from './../interfaces/account-stats.interface';

@Injectable()
export class AccountStatsResource {

  constructor(
    private http: HttpClient
  ) {}

  get(): Observable<IAccountStats> {
    return this.http.get<IAccountStats>(`${environment.api}/account/stats`);
  }
}
