import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PIXResource } from '../resources/pix.resource';
import { LoadPIXAction, UpdatePIXAction } from '../actions/pix.actions';
import { IPix } from './../interfaces/pix.interface';

@State<IPix>({
  name: 'pix',
  defaults: {
    type: null,
    key: null,
    document: null,
    name: null,
    city: null,
  }
})

@Injectable()
export class PIXState {
  constructor(
    private pixResource: PIXResource
  ) { }

  @Action(LoadPIXAction)
  async load(ctx: StateContext<IPix>) {
    try{
      const res: IPix = await this.pixResource.get().toPromise();

      ctx.setState(res);

    } catch (e){
      throw e;
    }
  }

  @Action(UpdatePIXAction)
  async update(ctx: StateContext<IPix>, { payload }) {
    try{
      const res = await this.pixResource.update(payload).toPromise();

      ctx.setState(res);

    } catch (e){
      throw e;
    }
  }
}
