import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { UIButtonThinComponent } from './ui-button-thin.component';

@NgModule({
  exports: [UIButtonThinComponent],
  declarations: [UIButtonThinComponent],
  imports: [
    CommonModule,
    IonicModule
  ]
})
export class UIButtonThinModule { }
