import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { UIDropdownOutlineComponent } from './ui-dropdown-outline.component';

@NgModule({
  declarations: [UIDropdownOutlineComponent],
  exports: [UIDropdownOutlineComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ]
})
export class UIDropdownOutlineModule { }
