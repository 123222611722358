import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { IAccountStats } from '../../interfaces/account-stats.interface';
import { IUser } from '../../interfaces/user.interface';
import { LoadAccountStatsAction } from './account-stats.actions';
import { AccountStatsState } from './account-stats.state';

@Injectable()
export class AccountStatsSandbox {
  @Select(AccountStatsState) stats$: Observable<IAccountStats>;

  constructor(
    private store: Store
  ) { }

  public snapshot(): IUser[]{
    return this.store.snapshot().accountStats;
  }

  public load() {
    return this.store.dispatch(new LoadAccountStatsAction());
  }

}

