import { AbstractControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ui-select',
  templateUrl: './ui-select.component.html',
  styleUrls: ['./ui-select.component.scss'],
})
export class UISelectComponent implements OnInit {
  @Input() label: String;
  @Input() control: AbstractControl;
  @Input() options: Object[];

  constructor() { 
  }

  ngOnInit() {}

}
