import { AbstractControl } from '@angular/forms';

export class ConfirmPasswordValidator {
  static validate(control: AbstractControl) {
    const controlPassword = control.parent.get('password');

    // controlPassword.statusChanges.subscribe(_ => {
    //   control.updateValueAndValidity();
    //   console.log('atualizando');
    // });

    if (control.value == controlPassword.value)
      return null;

    return { 'confirm.password.invalid': true };
  }
}