import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { IResponse } from '../../interfaces/response.interface';
import { IBilling } from './../../interfaces/billings.interface';
import { LoadBillings } from './billings.actions';
import { BillingsState } from './billings.state';

@Injectable()
export class BillingsSandbox {
  @Select(BillingsState) billings$: Observable<IResponse<IBilling>>;

  constructor(
    private store: Store
  ) { }
  
  public load() {
    return this.store.dispatch(new LoadBillings());
  }
}
