import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@auth';

const routes: Routes = [
  {
    path: 'mp/connect',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/mp-connect/mp-connect.module').then( m => m.MPConnectModule)
  },
  {
    path: 'medias/post',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/medias-post-page/medias-post-page.module').then( m => m.MediasPostPageModule)
  },
  {
    path: 'account',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountModule)
  },
  {
    path: 'account/menu',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/account-menu/account-menu.module').then( m => m.AccountMenuModule)
  },
  {
    path: 'account/wallet',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/account-wallet/account-wallet.module').then( m => m.AccountWalletModule)
  },
  {
    path: 'account/transfer',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/account-transfer/account-transfer.module').then( m => m.AccountTransferModule)
  },
  {
    path: 'account/wallet/documents',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/account-documents/account-documents.module').then( m => m.AccountDocumentsModule)
  },
  {
    path: 'account/wallet/register',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/account-wallet-register/account-wallet-register.module').then( m => m.AccountWalletRegisterModule)
  },
  {
    path: 'account/subscribers',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/account-subscribers/account-subscribers.module').then( m => m.AccountSubscribersModule)
  },
  {
    path: 'account/subscriptions/stats',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/account-subscriptions-stats/account-subscriptions-stats.module').then( m => m.AccountSubscriptionsStatsModule)
  },
  {
    path: 'account/billings',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/account-billings/account-billings.module').then( m => m.AccountBillingsModule)
  },
  {
    path: 'account/settings',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/account-settings/account-settings.module').then( m => m.AccountSettingsModule)
  },
  // {
  //   path: 'account/subscribers/:_id',
  //   canActivate: [AuthGuardService],
  //   loadChildren: () => import('./pages/account-subscribers-detail/account-subscribers-detail.module').then( m => m.AccountSubscribersDetailModule)
  // },
  {
    path: 'account/whitelist',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/account-whitelist/account-whitelist.module').then( m => m.AccountWhitelistModule)
  },
  {
    path: 'account/whitelist/add',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/account-whitelist-add/account-whitelist-add.module').then( m => m.AccountWhitelistAddModule)
  },
  {
    path: 'account/lastlink/login',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/account-lastlink-login/account-lastlink-login.module').then( m => m.AccountLastlinkLoginModule)
  },
  {
    path: 'account/lastlink',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/account-lastlink/account-lastlink.module').then( m => m.AccountLastlinkModule)
  },
  {
    path: 'account/stories/:_id',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/account-stories/account-stories.module').then( m => m.AccountStoriesModule)
  },
  {
    path: 'account/plan',
    loadChildren: () => import('./pages/account-plan/account-plan.module').then( m => m.AccountPlanModule)
  },
  {
    path: 'account/pix',
    loadChildren: () => import('./pages/account-pix/account-pix.module').then( m => m.AccountPIXModule)
  },
  {
    path: 'account/pix/receipts',
    loadChildren: () => import('./pages/account-pix-receipts/account-pix-receipts.module').then( m => m.AccountPixReceiptsModule)
  },
  {
    path: 'account/iugu-account',
    loadChildren: () => import('./pages/account-iugu-account/account-iugu-account.module').then( m => m.AccountIuguAccountModule)
  },
  {
    path: 'profile/edit',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/profile-edit/profile-edit.module').then( m => m.ProfileEditModule)
  },
  {
    path: 'profile/edit/name',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/profile-edit-name/profile-edit-name.module').then( m => m.ProfileEditNameModule)
  },
  {
    path: 'profile/edit/username',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/profile-edit-username/profile-edit-username.module').then( m => m.ProfileEditUsernameModule)
  },
  {
    path: 'profile/edit/email',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/profile-edit-email/profile-edit-email.module').then( m => m.ProfileEditEmailModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth-login/auth-login.module').then( m => m.AuthLoginModule)
  },
  {
    path: 'auth/register',
    loadChildren: () => import('./pages/auth-register/auth-register.module').then( m => m.AuthRegisterModule)
  },
  {
    path: '',
    redirectTo: 'account',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
