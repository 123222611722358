import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UIAlertService } from '../../ui/services/alert.service';
import { UILoadingService } from '../../ui/services/loading.service';

import {
  LoadMyStoriesAction,
  RemoveAccountStoryAction
} from '../actions/story.actions';
import { StoryModel } from '../models/story.model';
import { StoryResource } from '../resources/story.resource';
import { AccountStoriesState } from '../states/account-stories.state';

@Injectable()
export class StorySandbox {
  @Select(AccountStoriesState) myStories$: Observable<StoryModel[]>;
  @Select(AccountStoriesState.highlights) highlights$: Observable<StoryModel[]>;
  @Select(AccountStoriesState.closeds) closeds$: Observable<StoryModel[]>;

  constructor(
    private store: Store,
    private storyResource: StoryResource,
    private alertService: UIAlertService,
    private loadingService: UILoadingService
  ) {
    this.watchUnprocessedVideos();
  }

  public snapshot(){
    return this.store.snapshot().accountStories
      .filter(e => e.audience == 'closed');
  }

  public async uploadMediaFile(file, onProgress, onEnd, onError) {
    const formData = new FormData();

    try{
      if (file.type.indexOf('video') === -1) {
        return onError('Ops! Apenas vídeos são permitidos');
      } 

      const size = file.size / (1024 * 1024);
      const duration = await this.getVideoDuration(file);

     if (duration >= 16){
        return onError('Ops! Apenas vídeos com até 15 segundos são permitidos');
      } else if (size > 50){
        return onError('Ops! Apenas vídeos com tamanho de até 50mb são permitidos');
      }

      formData.append('file', file);

      return this.uploadClosed(formData).subscribe(e => {
        if (e.status === 'progress') {
          onProgress(e.progress);
        } else if (e.status === 'ended') {
          this.loadMyStories();
          onEnd(e.progress);
        }
      }, error => {
        onError(error.message);
      });
    }catch(e){
      alert(e);
      onError(e.message);
    }
  }

  private getVideoDuration(file) {
    return new Promise(resolve => {
      const video = document.createElement('video');

      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);

        const duration = video.duration;

        resolve(duration);
      };

      video.src = URL.createObjectURL(file);
    });
  }

  async presentVideoUploadError(error) {
    const customErr = error.error;
    const defaultMsg = 'Houve um erro ao processar seu vídeo. Tente novamente mais tarde.';
    const msg = customErr && customErr.message || defaultMsg;

    await this.alertService.present({
      header: 'Ops! Algo deu errado',
      subHeader: msg,
      buttons: ['Ok'],
    });
  }

  private watchUnprocessedVideos() {
    const state = this.store.snapshot();
    const stories = state.accountStories;

    const hasUnprocessed = !!stories.find(
      e => e.processed === false
    );

    if (hasUnprocessed) {
      this.loadMyStories();
    }

    setTimeout(() => this.watchUnprocessedVideos(), 5000);
  }

  public loadMyStories(): Observable<any> {
    return this.store.dispatch(new LoadMyStoriesAction());
  }

  public uploadClosed(file: FormData) {
    return this.storyResource.uploadClosed(file)
      .pipe(map(event => this.handleRequestUpload(event)));
  }

  public uploadHighlight(file: FormData) {
    return this.storyResource.uploadHighlight(file)
      .pipe(map(event => this.handleRequestUpload(event)));
  }

  private handleRequestUpload(event: HttpEvent<any>) {
    let progress = 0;
    let status = null;

    switch (event.type) {
      case HttpEventType.Sent:
        status = 'started';
        break;
      case HttpEventType.ResponseHeader:
        status = 'received';
        break;
      case HttpEventType.UploadProgress:
        progress = Math.floor((event.loaded / event.total) * 100);
        status = (progress === 100) ? 'uploaded' : 'progress';
        break;
      case HttpEventType.Response:
        progress = 0;
        status = 'ended';
    }

    return { progress, status };
  }

  public removeStory(_id: string): Observable<any> {
    return this.store.dispatch(new RemoveAccountStoryAction(_id));
  }

  public getMyStories() {
    return this.store.snapshot().accountStories;
  }
}
