import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ui-header',
  templateUrl: './ui-header.component.html',
  styleUrls: ['./ui-header.component.scss'],
})
export class UIHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() defaultHref: string;
  @Input() actionIcon: string;
  @Input() subActionIcon: string;

  @Output() actionClick = new EventEmitter();
  @Output() subActionClick = new EventEmitter();

  @Output() back = new EventEmitter();

  constructor() { }

  ngOnInit() {}

}
