import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { IUser } from '../../interfaces/user.interface';
import { LoadAdminPerformersAction } from './admin-performers.actions';
import { AdminPerformersState } from './admin-performers.state';

@Injectable()
export class AdminPerformersSandbox {
  @Select(AdminPerformersState) performers$: Observable<IUser[]>;

  constructor(
    private store: Store
  ) { }

  public snapshot(): IUser[]{
    return this.store.snapshot().adminPerformers;
  }

  public load() {
    return this.store.dispatch(new LoadAdminPerformersAction());
  }

}
