import { AbstractControl } from '@angular/forms';

export class CPFValidator {
  static validate(control: AbstractControl) {
    const cpf = control.value;

    if (cpf) {
      let numbers: any;
      let digits: any;
      let sum: number;
      let i;
      let result;
      let equalDigits: number;
      equalDigits = 1;

      if (cpf.length < 11) {
        return { 'cpf.invalid': true };
      }

      for (i = 0; i < cpf.length - 1; i++) {
        if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
          equalDigits = 0;
          break;
        }
      }

      if (!equalDigits) {
        numbers = cpf.substring(0, 9);
        digits = cpf.substring(9);
        sum = 0;
        for (i = 10; i > 1; i--) {
          sum += numbers.charAt(10 - i) * i;
        }

        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result !== Number(digits.charAt(0))) {
          return { 'cpf.invalid': true };
        }
        numbers = cpf.substring(0, 10);
        sum = 0;

        for (i = 11; i > 1; i--) {
          sum += numbers.charAt(11 - i) * i;
        }
        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result !== Number(digits.charAt(1))) {
          return { 'cpf.invalid': true };
        }
        return null;
      } else {
        return { 'cpf.invalid': true };
      }
    }
    return null;
  }
}
