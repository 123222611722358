import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { IIuguAccount } from '../../interfaces/iugu-account.interface';
import { LoadIuguAccountAction } from './iugu-account.actions';
import { IuguAccountState } from './iugu-account.state';

@Injectable()
export class IuguAccountSandbox {
  @Select(IuguAccountState) iuguAccount$: Observable<IIuguAccount>;

  constructor(
    private store: Store
  ) { }

  public load() {
    return this.store.dispatch(new LoadIuguAccountAction());
  }
}
