import { UIMaskLikeModule } from './../ui-media-like/ui-mask-like.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIAvatarModule } from '../ui-avatar/ui-avatar.module';
import { UIMediaCardComponent } from '../ui-media-card/ui-media-card.component';

@NgModule({
  declarations: [UIMediaCardComponent],
  exports: [UIMediaCardComponent],
  imports: [
    CommonModule,
    UIAvatarModule,
    UIMaskLikeModule,
  ]
})
export class UIMediaCardModule { }
