import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OperationModel } from '../models/operation.model';
import { WalletResource } from '../resources/wallet.resource';
import { CreateWalletAction, LoadBalanceOperationsAction } from './../actions/wallet.actions';

const defaults: OperationModel = {
  limit: 20,
  offset: 0,
  hasMore: null,
  object: null,
  totalCount: null,
  data: [],
  loading: false
};

@State<OperationModel>({
  name: 'operations',
  defaults
})

@Injectable()
export class OperationsState {
  constructor(
    private walletResource: WalletResource
  ) { }

  @Action(CreateWalletAction)
  clearSubscribers(ctx: StateContext<OperationModel>) {
    ctx.setState(defaults);
  }

  @Action(LoadBalanceOperationsAction)
  async loadBalanceOperations(ctx: StateContext<OperationModel>, { payload }) {
    try {
      ctx.patchState({ loading: true});

      const res: any = await this.walletResource
        .getBalanceOperations(payload)
        .toPromise();

      const state = ctx.getState();

      if (payload.offset === 0) {
        state.data = [];
      }

      res.data = state.data.concat(res.data);

      ctx.patchState({
        hasMore: res.hasMore,
        offset: res.offset,
        limit: res.limit,
        totalCount: res.totalCount,
        data: res.data,
        loading: false
      });

    } catch (e) {
      ctx.patchState({ loading: true });
      throw e;
    }
  }
}
