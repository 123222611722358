import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { IAccountSetting } from './../../interfaces/account-setting.interface';
import { GetSettingAction, PatchSettingAction } from './setting.actions';
import { SettingState } from './setting.state';

@Injectable()
export class SettingSandbox {
  @Select(SettingState) setting$: Observable<IAccountSetting>;

  constructor(
    private store: Store
  ) { }

  public snapshot(){
    return this.store.snapshot().accountSetting;
  }
  
  public get() {
    return this.store.dispatch(new GetSettingAction());
  }

  public patch(payload: IAccountSetting) {
    return this.store.dispatch(new PatchSettingAction(payload));
  }
}
