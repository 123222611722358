import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoginDTO } from '../dtos/login.dto';
import { RegisterDTO } from '../dtos/register.dto';
import { AuthResource } from '../resources/auth.resource';
import { AppSandbox } from './../../../../app.sandbox';

@Injectable()
export class AuthSandbox {
  constructor(
    private authResource: AuthResource,
    private router: Router,
    private appSandbox: AppSandbox,
    private route: ActivatedRoute
  ) { }

  public async login(payload: LoginDTO) {
    try{
      const code = this.route.snapshot.queryParams.code;

      payload.usernameOrEmail = payload.usernameOrEmail.trim().toLowerCase()
      payload.password = payload.password.trim();

      const res = await this.authResource.login(payload).toPromise();

      localStorage.setItem('accessToken', res.accessToken);

      if(code){
        this.router.navigate(['/mp/connect'], { queryParams: { code } });
      }else{
        this.router.navigate(['/']);
      }
      
      this.appSandbox.loadAppData();
    }catch (e){
      alert(e.error.message || e.error.code);
    }
  }

  public async register(payload: RegisterDTO) {
    try{
      const res = await this.authResource.register(payload).toPromise();

      localStorage.setItem('accessToken', res.accessToken);
      this.router.navigate(['/']);
      this.appSandbox.loadAppData();
    }catch (e){
      alert(e.error.message || e.error.code);
    }
  }
}
