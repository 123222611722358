import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AdminPerformersStateModule } from '@src/app/shared/modules/admin-performers/admin-performers.module';
import { UIHeaderModule, UISpinnerModule } from '@src/app/shared/modules/ui';
import { UIInputOutlineModule } from '@ui';

import { AdminPerformersComponent } from './admin-performers.component';

@NgModule({
  declarations: [AdminPerformersComponent],
  providers: [
  ],
  imports: [
    CommonModule,
    UIHeaderModule,
    IonicModule,
    UIInputOutlineModule,
    UISpinnerModule,
    AdminPerformersStateModule
  ]
})
export class AdminPerformersModule { }
