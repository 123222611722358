import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { StoriesArchivedResource } from './../../resources/stories-archived.resource';
import { StoriesArchivedSandbox } from './stories-archived.sandbox';
import { StoriesArchivedState } from './stories-archived.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      StoriesArchivedState
    ]),
  ],
  providers: [
    StoriesArchivedResource,
    StoriesArchivedSandbox
  ]
})
export class StoriesArchivedModule {}
