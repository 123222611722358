import { Component, Input, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';

import { IMedia } from '../../interfaces/media.interface';
import { IPagination } from '../../interfaces/pagination.interface';
import { IUser } from './../../interfaces/user.interface';
import { MeSandbox } from './../../modules/me/me.sandbox';
import { MediasSandbox } from './../../modules/medias/medias.sandbox';
import { MediasLikeResource } from './../../resources/media-like.resource';

@Component({
  selector: 'app-medias-feed',
  templateUrl: './medias-feed.component.html',
  styleUrls: ['./medias-feed.component.scss']
})
export class MediasFeedComponent implements OnInit {
  @Input() selected: string;

  public me$: Observable<IUser> = this.meSandbox.me$;

  public medias$: Observable<IPagination<IMedia>> = this.mediasSandbox.medias$;

  constructor(
    private mediasSandbox: MediasSandbox,
    private meSandbox: MeSandbox,
    private alertController: AlertController,
    private mediasLikeResource: MediasLikeResource
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    setTimeout(() => {
      document.getElementById(this.selected).scrollIntoView({
        block: 'start'
      });
    }, 0)

  }

  public async remove(_id) {
    const alert = await this.alertController.create({
      header: 'Excluir publicação?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Excluir',
          handler: () => {
            this.mediasSandbox.delete(_id).toPromise();
          },
        },
      ],
    });

    await alert.present();
  }  

  onLike(like: boolean, media: IMedia) {
    if (!media.hasLiked) {
      this.mediasLikeResource.unlike(media._id).toPromise();
    } else {
      this.mediasLikeResource.like(media._id).toPromise();
    }
  }
}
