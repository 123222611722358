import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

import { IBilling } from '../interfaces/billings.interface';
import { IResponse } from '../interfaces/response.interface';

@Injectable()
export class BillingResource {

  constructor(
    private http: HttpClient
  ) {}

  get(): Observable<IResponse<IBilling>> {
    return this.http.get<IResponse<IBilling>>(`${environment.api}/billings`);
  }

  uploadReceipt(_id: string, payload: FormData) {
    return this.http.post(`${environment.api}/billings/${_id}/receipt`, payload, {
      headers: new HttpHeaders({ 'ngsw-bypass': 'true' })
    });
  }
}
