import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

export class AlertModel{
  header?: string;
  subHeader?: string;
  buttons?: Array<any>;
}

@Injectable()
export class UIAlertService {
  constructor(
    private alertController: AlertController
  ) {}

  public async present(payload: AlertModel){
    const alert = await this.alertController.create({
      header: payload.header,
      subHeader: payload.subHeader,
      buttons: payload.buttons,
    });

    await alert.present();

    return alert;
  }
}
