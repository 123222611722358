import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router
  ) {}

  canActivate(snapshot: ActivatedRouteSnapshot) {
    const accessToken = localStorage.getItem('accessToken');
    const queryParams =  snapshot.queryParams;

    if (!accessToken){
      return this.router.navigate(['/auth'], { queryParams: queryParams });
    }

    return true;
  }
}
