import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';
import { IPayment } from '../interfaces/payment.interface';

import { IPaymentsGetDTO } from './../dtos/payments-get.dto';

@Injectable()
export class PaymentResource {

  constructor(
    private http: HttpClient
  ) {}

  get(): Observable<IPaymentsGetDTO> {
    return this.http.get<IPaymentsGetDTO>(`${environment.api}/payments?paymentMethod=pix&hasReceiptUrl&receiptManualApproved=null`);
  }

  approve(paymentId: string): Observable<IPayment> {
    return this.http.post<IPayment>(`${environment.api}/payments/${paymentId}/approve`, null);
  }

  reprove(paymentId: string): Observable<IPayment> {
    return this.http.post<IPayment>(`${environment.api}/payments/${paymentId}/reprove`, null);
  }

  refund(paymentId: string){
    return this.http.post<IPayment>(`${environment.api}/iugu/payments/${paymentId}/refund`, null);
  }
}
