import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[gestures]'
})
export class GesturesDirective {
    @Output() doubleTap = new EventEmitter();

    constructor() { }

    @HostListener('tap', ['$event'])
    onTap(e) {
        if (e.tapCount === 2) {
            this.doubleTap.emit(e);
        }
    }
}