import { Injectable } from '@angular/core';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';

import { IBilling } from '../../interfaces/billings.interface';
import { IResponse } from '../../interfaces/response.interface';
import { BillingResource } from './../../resources/billing.resource';
import { LoadBillings } from './billings.actions';

@State<IResponse<IBilling>>({
  name: 'billings'
})

@Injectable()
export class BillingsState {
  @Select(BillingsState) billings$: Observable<IResponse<IBilling>>;

  constructor(
    private billingResource: BillingResource
  ) { }

  @Action(LoadBillings)
  async load(ctx: StateContext<IResponse<IBilling>>) {
    try {
      const res: IResponse<IBilling> = await this.billingResource.get().toPromise();

      ctx.setState(res);
    } catch (e) {
      throw e;
    }
  }

}
