import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

import { IAccountSetting } from './../interfaces/account-setting.interface';

@Injectable({ providedIn: 'root' })
export class AccountSettingResource {

  constructor(
    private http: HttpClient
  ) {}

  get(): Observable<IAccountSetting> {
    return this.http.get<IAccountSetting>(`${environment.api}/account/setting`);
  }

  patch(payload: IAccountSetting): Observable<IAccountSetting> {
    return this.http.patch<IAccountSetting>(`${environment.api}/account/setting`, payload);
  }
}