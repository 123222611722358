import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { LoadWalletStatusAction } from '../actions/wallet.actions';
import { WalletResource } from '../resources/wallet.resource';
import { WalletStatusModel } from './../models/wallet-status.model';

@State<WalletStatusModel>({
  name: 'document'
})

@Injectable()
export class WalletStatusState {

  constructor(
    private walletResource: WalletResource
  ) { }

  @Action(LoadWalletStatusAction)
  async loadWalletStatus(ctx: StateContext<WalletStatusModel>) {
    try{
      const res = await this.walletResource
        .getStatus()
        .toPromise();

      ctx.setState(res);

    } catch (e){
      throw e;
    }
  }
}
