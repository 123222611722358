import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UISimpleCardComponent } from '../ui-simple-card/ui-simple-card.component';

@NgModule({
  declarations: [UISimpleCardComponent],
  exports: [UISimpleCardComponent],
  imports: [
    CommonModule
  ]
})
export class UISimpleCardModule { }
