import { UpdatePlanDTO } from '../dtos/update-plan.dto';

export class LoadPlanAction {
  static readonly type = '[Plan] Load Plan';
}

export class UpdatePlanAction {
  static readonly type = '[Plan] Update Plan';

  constructor(public payload: UpdatePlanDTO){}
}

