import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

import { IIuguAccount } from '../interfaces/iugu-account.interface';

@Injectable()
export class IuguResource {

  constructor(
    private http: HttpClient
  ) {}

  getAccount(): Observable<IIuguAccount> {
    return this.http.get<IIuguAccount>(`${environment.api}/iugu/account`);
  }
}
