import { Injectable } from '@angular/core';
import { PIXSandbox } from '@pix';
import { ProfileSandbox } from '@profile';
import { PixReceiptSandbox } from '@src/app/shared/modules/pix-receipts';
import { StorySandbox } from '@story';
import { SubscriptionSandbox } from '@subscription';
import { WalletSandbox } from '@wallet';
import { forkJoin } from 'rxjs';

import { AccountStatsSandbox } from './shared/modules/account-stats/account-stats.sandbox';
import { BillingsSandbox } from './shared/modules/billings/billings.sandbox';
import { HighlightsSandbox } from './shared/modules/highlights/highlights.sandbox';
import { MeSandbox } from './shared/modules/me/me.sandbox';
import { MediasSandbox } from './shared/modules/medias/medias.sandbox';
import { PlanSandbox } from './shared/modules/plan/sandboxes/plan.sandbox';
import { SettingSandbox } from './shared/modules/setting/setting.sandbox';
import { StoriesArchivedSandbox } from './shared/modules/stories-archived/stories-archived.sandbox';
import { IuguAccountSandbox } from './shared/states/iugu-account/iugu-account-sandbox';

@Injectable({ providedIn: 'root' })
export class AppSandbox {
  constructor(
    private storySandbox: StorySandbox,
    private walletSandbox: WalletSandbox,
    private subscriptionSandbox: SubscriptionSandbox,
    private profileSandbox: ProfileSandbox,
    private planSandbox: PlanSandbox,
    private PIXSandbox: PIXSandbox,
    private PixReceiptSandbox: PixReceiptSandbox,
    private billingsSandbox: BillingsSandbox,
    private mediasSandbox: MediasSandbox,
    private meSandbox: MeSandbox,
    private storiesArchivedSandbox: StoriesArchivedSandbox,
    private highlightsSandbox: HighlightsSandbox,
    private accountStatsSandbox: AccountStatsSandbox,
    private settingSandbox: SettingSandbox,
    private iuguAccountSandbox: IuguAccountSandbox
  ){}

  loadAppData(){
    forkJoin([
      this.storySandbox.loadMyStories(),
      this.subscriptionSandbox.loadStats(),
      this.walletSandbox.loadWallet(),
      this.walletSandbox.loadBalance(),
      this.walletSandbox.loadBalanceOperations(),
      this.profileSandbox.loadMyProfile(),
      this.planSandbox.loadPlan(),
      this.walletSandbox.loadWalletStatus(),
      this.PIXSandbox.load(),
      this.PixReceiptSandbox.load(),
      this.billingsSandbox.load(),
      this.mediasSandbox.load(),
      this.meSandbox.load(),
      this.storiesArchivedSandbox.load(),
      this.highlightsSandbox.load(),
      this.accountStatsSandbox.load(),
      this.settingSandbox.get(),
      this.iuguAccountSandbox.load()
    ]).subscribe();
  }
}
