import { WalletSearchDTO } from '../dtos/wallet-search.dto';
import { CreateWalletDTO } from './../dtos/create-wallet.dto';

export class LoadBalanceAction {
  static readonly type = '[Wallet] Load Balance';

  constructor() { }
}

export class LoadBalanceOperationsAction {
  static readonly type = '[Wallet] Load Balance Operations';

  constructor(public payload: WalletSearchDTO) { }
}

export class LoadRecipientAction {
  static readonly type = '[Wallet] Load Recipient';

  constructor() { }
}

export class LoadPayablesAction {
  static readonly type = '[Wallet] Load Payables';

  constructor() { }
}

export class CreateWalletAction {
  static readonly type = '[Wallet] Create Wallet';

  constructor(public payload: CreateWalletDTO) { }
}

export class LoadWalletAction {
  static readonly type = '[Wallet] Load Wallet';

  constructor() { }
}

export class ClearWalletOperationsAction {
  static readonly type = '[Wallet] Clear Wallet Operatinos';

  constructor() { }
}

export class LoadWalletStatusAction {
  static readonly type = '[Wallet] Load Status';

  constructor() { }
}





