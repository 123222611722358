import { UploadClosedStoryAction, UploadHighlightStoryAction } from './../actions/story.actions';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { LoadMyStoriesAction, RemoveAccountStoryAction } from '../actions/story.actions';
import { StoryModel } from '../models/story.model';
import { StoryResource } from '../resources/story.resource';

@State<StoryModel[]>({
  name: 'accountStories',
  defaults: []
})

@Injectable()
export class AccountStoriesState {

  @Selector()
  static highlights(state: StoryModel[]) {
    return state.filter(e => e.audience === 'highlight');
  }

  @Selector()
  static closeds(state: StoryModel[]) {
    return state.filter(e => e.audience === 'closed');
  }

  constructor(
    private storyResource: StoryResource
  ) { }

  @Action(LoadMyStoriesAction)
  loadMyStories(ctx: StateContext<StoryModel[]>) {
    return this.storyResource.findMyStories().pipe(
      map((stories: StoryModel[]) => {
        ctx.setState(stories);
      })
    );
  }

  @Action(RemoveAccountStoryAction)
  removeAccountStory(ctx: StateContext<StoryModel[]>, { payload }: RemoveAccountStoryAction) {
    const _id     = payload;
    const stories = this.clone(ctx.getState());
    const index   = stories.findIndex(e => e._id === _id);

    stories.splice(index, 1);

    ctx.setState(stories);

    return this.storyResource.deleteOne(payload).pipe(
      map(() => {
        ctx.dispatch(LoadMyStoriesAction);
      })
    );
  }

  private clone(data){
    return JSON.parse(JSON.stringify(data));
  }
}
