import { AbstractControl } from '@angular/forms';

export class EmailValidator {
  static validate(control: AbstractControl) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(control.value) == false) {
      return { 'email.invalid': true };
    }

    return null;
  }
}
