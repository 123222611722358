import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AuthInterceptor } from '@auth';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { PlanModule } from '@plan';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountMenuModule } from './pages/account-menu/account-menu.module';
import { MediasFeedModule } from './shared/components/medias-feed/medias-feed.module';
import { AccountStatsModule } from './shared/modules/account-stats/account-stats.module';
import { BillingsModule } from './shared/modules/billings/billings.module';
import { HighlightsModule } from './shared/modules/highlights/highlights.module';
import { MeModule } from './shared/modules/me/me.module';
import { MediasModule } from './shared/modules/medias/medias.module';
import { PIXModule } from './shared/modules/pix';
import { PixReceiptsModule } from './shared/modules/pix-receipts';
import { SeenModule } from './shared/modules/seen/seen.module';
import { SettingModule } from './shared/modules/setting/setting.module';
import { StoriesArchivedModule } from './shared/modules/stories-archived/stories-archived.module';
import { StoryModule } from './shared/modules/story';
import { SubscriptionsStatsModule } from './shared/modules/subscriptions-stats/subscriptions-stats.module';
import { IuguAccountStateModule } from './shared/states/iugu-account/iugu-account.module';


registerLocaleData(localePt);

class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    press: { time: 10 }
  };
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      // mode: 'md'
      animated: false
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    AppRoutingModule,
    NgxsModule.forRoot([]),
    NgxsStoragePluginModule.forRoot({
      key: [
        'profile',
        'plan',
        'accountStories',
        'subscription',
        'balance',
        'operations',
        'payables',
        'recipient',
        'lastlink',
        'pix',
        'subscriptionsStats',
        'adminPerformers',
        'billings',
        'medias',
        'me',
        'storiesArchived',
        'highlights',
        'seen',
        'accountStats',
        'accountSetting',
        'iuguAccount'
      ]
    }),
    SettingModule,
    NgxsResetPluginModule.forRoot(),
    AccountStatsModule,
    SeenModule,
    HighlightsModule,
    MeModule,
    StoryModule,
    PlanModule,
    PIXModule,
    PixReceiptsModule,
    SubscriptionsStatsModule,
    BillingsModule,
    MediasModule,
    MediasFeedModule,
    StoriesArchivedModule,
    IuguAccountStateModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AccountMenuModule,
    HammerModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
