import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GesturesDirective } from '../../directives/gestures.directive';
import { UIMaskLikeComponent } from './ui-mask-like.component';

@NgModule({
  declarations: [
    UIMaskLikeComponent,
    GesturesDirective,
  ],
  exports: [
    UIMaskLikeComponent,
  ],
  imports: [
    CommonModule,
  ]
})
export class UIMaskLikeModule { }
