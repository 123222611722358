import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ISeen } from '../../interfaces/seen.interface';
import { IStory } from '../../interfaces/story.interface';
import { SetSeenAction } from './seen.actions';
import { SeenState } from './seen.state';

@Injectable()
export class SeenSandbox {
  @Select(SeenState) seen$: Observable<ISeen>;

  constructor(
    private store: Store
  ) { }

  public snapshot(){
    return this.store.snapshot().seen;
  }
  
  public set(time: number) {
    return this.store.dispatch(new SetSeenAction(time));
  }
}
