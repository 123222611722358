import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'ui-mask-like',
  templateUrl: './ui-mask-like.component.html',
  styleUrls: ['./ui-mask-like.component.scss']
})
export class UIMaskLikeComponent implements OnInit {
  @ViewChild('iconLike') iconLike: ElementRef;
  @Output() like = new EventEmitter();

  public liked: boolean;
  private timeout;

  constructor() { }

  ngOnInit(): void {
  }

  onDoubleTap() {
    this.like.emit();
    this.liked = true;

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.liked = false;
    }, 800);
  }
}
