import { UpdateProfileAction } from './../actions/profile.actions';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';

import { LoadMyProfileAction } from '../actions/profile.actions';
import { ProfileModel } from '../models/profile.model';
import { ProfileResource } from '../resources/profile.resource';

@State<ProfileModel>({
  name: 'profile'
})

@Injectable()
export class ProfileState {

  constructor(
    private profileResource: ProfileResource
  ) { }

  @Action(LoadMyProfileAction)
  loadMyProfile(ctx: StateContext<ProfileModel>) {
    return this.profileResource.findMe().pipe(
      map((profile: ProfileModel) => {
        ctx.setState(profile);
      })
    );
  }

  @Action(UpdateProfileAction)
  updateProfile(ctx: StateContext<ProfileModel>, { payload }) {
    return this.profileResource.update(payload).pipe(
      map((profile: ProfileModel) => {
        ctx.setState(profile);
      })
    );
  }
}
