import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { ProfileResource } from '../profile/resources/profile.resource';
import { MeSandbox } from './me.sandbox';
import { MeState } from './me.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      MeState
    ]),
  ],
  providers: [
    ProfileResource,
    MeSandbox
  ]
})
export class MeModule {}
