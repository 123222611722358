import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

import { IUser } from '../interfaces/user.interface';

@Injectable({ providedIn: 'root' })
export class PerformerResource {

  constructor(
    private http: HttpClient
  ) {}

  getPerformers(): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${environment.api}/admin/performers`);
  }

}
