import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-menu-container',
  templateUrl: './ui-menu-container.component.html',
  styleUrls: ['./ui-menu-container.component.scss']
})
export class UIMenuContainerComponent implements OnInit {
  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
