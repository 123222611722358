import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { PaymentResource } from '../../resources/payment.resource';
import { PixReceiptSandbox } from './sandboxes/pix-receipts.sandbox';
import { PixReceiptsState } from './states/pix-receipts.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      PixReceiptsState
    ]),
  ],
  providers: [
    PaymentResource,
    PixReceiptSandbox
  ]
})
export class PixReceiptsModule {}
