import { Component, OnInit } from '@angular/core';
import { WalletSandbox } from '@wallet';

@Component({
  selector: 'app-account-balance',
  templateUrl: './account-balance.component.html',
  styleUrls: ['./account-balance.component.scss']
})
export class AccountBalanceComponent implements OnInit {
  public balance$ = this.walletSandbox.balance$;

  constructor(
    private walletSandbox: WalletSandbox
  ) { }

  ngOnInit() {}

}
