import { Component, OnInit, ElementRef } from '@angular/core';
import { Element } from '@angular/compiler';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ui-tab',
  templateUrl: './ui-tab.component.html',
  styleUrls: ['./ui-tab.component.scss']
})
export class UITabComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {}
}
