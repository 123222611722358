import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

import { UIInputPixComponent } from '../ui-input-pix/ui-input-pix.component';

@NgModule({
  declarations: [UIInputPixComponent],
  exports: [UIInputPixComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ]
})
export class UIInputPixModule { }
