import { IAccountSetting } from './../../interfaces/account-setting.interface';

export class GetSettingAction {
  static readonly type = '[Setting] Get Setting';

}

export class PatchSettingAction {
  static readonly type = '[Setting] Patch Setting';

  constructor(public payload: IAccountSetting) { }
}