import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-segment-button',
  templateUrl: './ui-segment-button.component.html',
  styleUrls: ['./ui-segment-button.component.scss'],
})
export class UISegmentButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
