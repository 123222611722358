import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthResource } from './resources/auth.resource';
import { AuthSandbox } from './sandboxes/auth.sandbox';

@NgModule({
  imports: [
    RouterModule
  ],
  providers: [
    AuthResource,
    AuthSandbox
  ]
})
export class AuthModule {}
