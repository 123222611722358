import { IPayment } from './../../../interfaces/payment.interface';
import { Injectable } from '@angular/core';
import { IPaymentsGetDTO } from '@dtos/payments-get.dto';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ApproveReceiptPixAction, LoadPixReceiptsAction, ReproveReceiptPixAction } from '../actions/pix-receipt.actions';
import { PixReceiptsState } from '../states/pix-receipts.state';

@Injectable()
export class PixReceiptSandbox {
  @Select(PixReceiptsState) pixReceipts$: Observable<IPaymentsGetDTO>;

  constructor(
    private store: Store
  ) { }

  public snapshot(): IPaymentsGetDTO{
    return this.store.snapshot().pix;
  }

  public load() {
    return this.store.dispatch(new LoadPixReceiptsAction());
  }

  public approve(paymentId: string){
    return this.store.dispatch(new ApproveReceiptPixAction(paymentId));
  }

  public reprove(paymentId: string){
    return this.store.dispatch(new ReproveReceiptPixAction(paymentId));
  }

}
