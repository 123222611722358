import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[capitalizefirsts="true"]'
})
export class CapitalizeFirstsDirective {
  constructor(
    private readonly control: NgControl
  ) { }

  @HostListener('input', ['$event'])
  public onInput(event): void {
    const currentValue = event.target.value;
    const parts = currentValue.split(' ');

    const newValue = parts.map(e => (
      e.charAt(0).toUpperCase() + e.slice(1)
    )).join(' ')

    

    this.control.control.setValue(newValue);
  }
}