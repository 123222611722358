import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

import { IMedia } from '../interfaces/media.interface';
import { IPagination } from '../interfaces/pagination.interface';

@Injectable()
export class MediasResource {

  constructor(
    private http: HttpClient
  ) {}

  get(): Observable<IPagination<IMedia>> {
    return this.http.get<IPagination<IMedia>>(`${environment.api}/medias`);
  }

  post(payload: FormData) {
    return this.http.post<IMedia>(`${environment.api}/medias`, payload, {
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({ 'ngsw-bypass': 'true' })
    });
  }

  delete(_id: string) {
    return this.http.delete(`${environment.api}/medias/${_id}`);
  }
}
