import { IHighlight } from '../../interfaces/highlight.interface';
import { IStory } from '../../interfaces/story.interface';

export class LoadHighlightsAction {
  static readonly type = '[Highlights] Load highlights';
};

export class PostHighlightAction {
  static readonly type = '[Highlights] Post highlight';

  constructor(public payload: IHighlight){}
};

export class DeleteHighlightStoryAction {
  static readonly type = '[Highlights] Delete highlight story';

  constructor(public payload: { highlight: IHighlight, story: IStory }){}
};