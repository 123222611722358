import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { AccountStatsResource } from '@resources/account-stats.resource';

import { IAccountStats } from './../../interfaces/account-stats.interface';
import { LoadAccountStatsAction } from './account-stats.actions';

@State<IAccountStats>({
  name: 'accountStats'
})

@Injectable()
export class AccountStatsState {

  constructor(
    private accountStatsResource: AccountStatsResource
  ) { }

  @Action(LoadAccountStatsAction)
  async loadPerformers(ctx: StateContext<IAccountStats>) {
    try{
      const res: IAccountStats= await this.accountStatsResource.get()
        .toPromise();

      ctx.setState(res);
    }catch(e){
      throw e;
    }
  }
}
