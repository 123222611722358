import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

import { IPagination } from './../interfaces/pagination.interface';
import { IStory } from './../interfaces/story.interface';

@Injectable({ providedIn: 'root' })
export class StoriesArchivedResource {

  constructor(
    private http: HttpClient
  ) {}

  get(): Observable<IPagination<IStory>> {
    return this.http.get<IPagination<IStory>>(`${environment.api}/stories/archived`);
  }

}
