import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-simple-card',
  templateUrl: './ui-simple-card.component.html',
  styleUrls: ['./ui-simple-card.component.scss']
})
export class UISimpleCardComponent implements OnInit {
  @Input() key: string;
  @Input() value: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
