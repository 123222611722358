import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { UIMenuContainerModule, UISimpleCardModule } from '@ui';

import { SharedModule } from './../../shared.module';
import { AccountBalanceComponent } from './account-balance.component';

@NgModule({
  declarations: [AccountBalanceComponent],
  exports: [AccountBalanceComponent],
  imports: [
    CommonModule,
    SharedModule,
    UISimpleCardModule,
    UIMenuContainerModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }]
})
export class AccountBalanceModule { }
