import { Injectable } from '@angular/core';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';

import { IHighlight } from '../../interfaces/highlight.interface';
import { HighlightsResource } from '../../resources/highlights.resource';
import { DeleteHighlightStoryAction, LoadHighlightsAction, PostHighlightAction } from './highlights.actions';

@State<IHighlight[]>({
  name: 'highlights',
  defaults: []
})

@Injectable()
export class HighlightsState {
  @Select(HighlightsState) medias$: Observable<IHighlight[]>;

  constructor(
    private highlightsResource: HighlightsResource
  ) { }

  @Action(LoadHighlightsAction)
  async load(ctx: StateContext<IHighlight[]>) {
    try{
      const res: IHighlight[] = await this.highlightsResource.get()
        .toPromise();

      ctx.setState(res);
    }catch(e){
      throw e;
    }
  }

  @Action(PostHighlightAction)
  async post(ctx: StateContext<IHighlight>, { payload }) {
    try{
      await this.highlightsResource.post(payload).toPromise();

      ctx.dispatch(LoadHighlightsAction);
    }catch(e){
      throw e;
    }
  }

  @Action(DeleteHighlightStoryAction)
  async delete(ctx: StateContext<IHighlight>, { payload }) {
    try{
      await this.highlightsResource.delete(
        payload.highlight._id,
        payload.story._id
      ).toPromise();

      ctx.dispatch(LoadHighlightsAction);
    }catch(e){
      throw e;
    }
  }
}
