import { Injectable } from '@angular/core';
import { Action, Select, State, StateContext } from '@ngxs/store';
import { SubscriptionResource } from '@resources/subscriptions.resource';
import { Observable } from 'rxjs';

import { ISubscriptionsStats } from '../../interfaces/subscriptions-stats.interface';
import { LoadSubscriptionsStatsAction } from './subscriptions-stats.actions';

@State<ISubscriptionsStats>({
  name: 'subscriptionsStats'
})

@Injectable()
export class SubscriptionsStatsState {
  @Select(SubscriptionsStatsState) subscriptions$: Observable<ISubscriptionsStats>;

  constructor(
    private subscriptionResource: SubscriptionResource
  ) { }

  @Action(LoadSubscriptionsStatsAction)
  async loadSubscriptionsStats(ctx: StateContext<ISubscriptionsStats>) {
    try {
      const res: ISubscriptionsStats = await this.subscriptionResource
        .getStats()
        .toPromise();

      ctx.setState(res);
    } catch (e) {
      throw e;
    }
  }

}
