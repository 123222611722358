import { AbstractControl } from '@angular/forms';

export class UsernameOrEmailValidator {
  static validate(c: AbstractControl) {
    const regUser = /^[A-Za-z0-9_\-\.]{3,50}$/;
    const regEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (!regUser.test(c.value) && !regEmail.test(c.value)) {
      return { 'login.invalid': true };
    }

    return null;
  }
}