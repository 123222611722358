import { AbstractControl } from '@angular/forms';

export class CNPJValidator {
  static validate(c: AbstractControl) {
    if ( ( cnpjUnmasked( c ) === null) || ( cnpjUnmasked( c ).length < 14) ) {
      return { 'cnpj.invalid': true };
    }

    if ( isAllCharacteresEquals( c ) ) {
      return { 'cnpj.invalid': true };
    }

    let size: any = cnpjUnmasked( c ).length - 2;
    let numbers: any = cnpjUnmasked( c ).substring( 0, size );
    const digits: any = cnpjUnmasked( c ).substring( size );

    let sum = 0;
    let pos = size - 7;
    let result;

    for ( let i = size; i >= 1; i-- ) {
      sum += numbers.charAt( size - i ) * pos--;
      if ( pos < 2 ) {
        pos = 9;
      }
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if ( result !== parseInt( digits.charAt( 0 ), 10 ) ) {
      return { 'cnpj.invalid': true };
    }

    size = size + 1;
    numbers = cnpjUnmasked( c ).substring( 0, size );
    sum = 0;
    pos = size - 7;
    for ( let i = size; i >= 1; i-- ) {
      sum += numbers.charAt( size - i ) * pos--;
      if ( pos < 2 ) {
        pos = 9;
      }
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;

    if ( result !== parseInt( digits.charAt( 1 ), 10 ) ) {
      return { 'cnpj.invalid': true };
    }

    return null;
  }
}

function cnpjUnmasked( c ) {
  return String( c.value ).replace( /(\/|\.|-|_|\(|\)|:|\+)/gi, '' );
}

function isAllCharacteresEquals( c ) {
  let result = true;
  for ( let i = 1; i <= 9; i++ ) {
    if ( cnpjUnmasked( c ).substring( i - 1, i ) !== cnpjUnmasked( c )[ 0 ] ) {
      result = false;
      break;
    }
  }
  return result;
}