export class WalletModel{
  name: string;
  document: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  zipcode: string;
  hasWalletId?: string;
  hasApiKey?: string;
  meta?: any;
}
