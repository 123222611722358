import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIInputOutlineComponent } from './ui-input-outline.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [UIInputOutlineComponent],
  exports: [UIInputOutlineComponent],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule
  ]
})
export class UIInputOutlineModule { }
